/*Umbraco forms
*********************************************************************/

#contour {
  input.text,
  textarea {
    max-width: 100% !important;
    width: 100%;
  }
}
.contour {
  input.text,
  textarea {
    max-width: 100% !important;
    width: 100%;
  }
}

#countour,
.contour {
    &.form-font-sm {
        font-size: 0.8em;
    }

    &.form-padding {
        padding: 25px;
    }

    &.form-border {
        border: 1px solid #DDD;
        border-radius: 5px;
    }

    .umbraco-forms.missing-library {
        //Add this because the popup shows on IE but Jquery is loaded and jquery validate work well
        display: none;
    }

    .contourPage {
        .contourFieldSet {
            position: relative;

            input.text,
            input.title,
            textarea,
            select {
                max-width: 100% !important;
                padding: 13px;
                display: block;
                width: 100%;
                border: 1px solid gainsboro;
                border-radius: 3px;

                &:focus {
                    outline: none;
                    border: 1px solid gainsboro;
                    box-shadow: 0 0 10px gainsboro;
                }

                &.input-validation-error {
                    border: 2px solid @novicell-color;
                    background-color: transparent;

                    &:after {
                        content: "✖";
                        color: @novicell-color;
                        font-size: 18px;
                        display: block;
                        position: relative;
                    }
                }
            }

            input[type=file]  {
                width: 100%;
            }
            
            select {
                option {
                    &:hover {
                        background-color: @novicell-color;
                        color: @color-white;
                    }
                }
            }

            div {
                position: relative;
                float: left;
                width: 100%;

                &.error {
                    &:after {
                        content: "✖";
                        color: @novicell-color;
                        font-size: 18px;
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                    }
                }

                &.correct {
                    &:after {
                        content: "✔ ";
                        color: @color-secondary;
                        font-size: 18px;
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                        font-weight: bold;
                    }
                }
            }

            .field-validation-error {
                span {
                    color: @novicell-color;
                    font-size: 16px;
                }
            }

            textarea {
                resize: none;
            }

            input[type=file] {
                padding-bottom: 13px;
            }
        }
    }


    .fieldLabel {
        padding: 0;
    }

    .checkbox,
    .checkboxlist {
        label {
            display: inline-block;
            cursor: pointer;
            position: relative;
            padding-left: 35px;
            margin-right: 15px;
            font-size: 16px;
            font-weight: normal !important;

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                position: absolute;
                margin-right: 10px;
                left: 0;
                bottom: 1px;
                background-color: @color-white;
                border: 1px solid gainsboro;
                border-radius: 3px;
                box-shadow: 0 0px 5px gainsboro inset;
            }

            &.checked {
                &:before {
                    content: "\2713";
                    text-shadow: 1px 1px 1px @novicell-color;
                    font-size: 14px;
                    color: @novicell-color;
                    text-align: center;
                    line-height: 19px;
                    font-weight: bold;
                }
            }
        }

        input[type="checkbox"] {
            display: none !important;
            -webkit-appearance: none;
        }

        &.not-checked,
        &.list-not-checked {
            background-color: lighten(@novicell-color, 30%);
            padding: 10px;
            width: 100%;
        }

        & + span.field-validation-error {
            color: @novicell-color !important;
            font-size: 16px;
        }
    }

    .radiobuttonlist,
    .radiobutton {
        label {
            display: inline-block;
            cursor: pointer;
            position: relative;
            padding-left: 35px;
            margin-right: 15px;
            font-size: 16px;
            color: @color-text;

            &:before {
                content: "";
                display: inline-block;
                width: 20px;
                height: 20px;
                position: absolute;
                margin-right: 10px;
                left: 0;
                bottom: 1px;
                background-color: @color-white;
                border: 1px solid gainsboro;
                border-radius: 10px;
                box-shadow: 0 0px 5px gainsboro inset;
            }
        }

        input[type="radio"] {
            display: none !important;
            -webkit-appearance: none;

            &:checked + label:before {
                content: "●";
                text-shadow: 1px 1px 1px @novicell-color;
                font-size: 20px;
                color: @novicell-color;
                text-align: center;
                line-height: 15px;
            }
        }

        &.not-checked,
        &.list-not-checked {
            background-color: lighten(@novicell-color, 30%);
            padding: 10px;
            width: 100%;
        }

        & + span.field-validation-error {
            color: @novicell-color !important;
            font-size: 16px;
        }
    }

    .nice-select {
        margin-bottom: 20px;
    }

    .umbraco-form-help-text {
        position: absolute !important;
        right: 0;
        top: 0;
        width: 100px;
        text-align: right;
        float: right;

        .help-tip {
            display: none;
            position: absolute !important;
            top: -60px;
            width: 200px;
            left: -35%;
            min-height: 50px;
            text-align: left;
            font-size: 14px;
            background-color: @color-white;
            border: 1px solid gainsboro;
            border-radius: 3px;
            box-shadow: 0 0 20px gainsboro;
            padding: 10px;

            &:hover {
                display: block;
            }

            &:after {
                content: " ";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-top: 10px solid @color-white;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -10px;
                margin: auto;
                //box-shadow: 0 0 20px gainsboro;
            }
        }

        .get-help {
            color: @novicell-color;
            text-decoration: underline;
            font-size: 16px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .contourNavigation {
        display: inline-block;
    }
}

/* Hubspot forms styles  */
.nc-grid-hubspotform {
    width: 50%;
    margin: 0 auto;

    @media (max-width:@screen-sm) {
        width: 100%;
    }
}

.nc-grid-hubspotform .actions {
    text-align: center;
}

.hbspt-form {

      label {
          font-family: @base-font-family;
          font-weight: bold;
      }

      .input {
          line-height: normal;
          border-radius: 0px;
          padding-left: 1px;
          border:none;
          width: unset;
          height: unset;
          padding: unset;
          background: none;
      }

      .hs-input {
            padding: 10px 10px 5px 5px;
            border: 1px solid gainsboro;
            border-radius: 3px;
            font-size: 18px;
            &:focus {
              box-shadow: 0 0 10px gainsboro;
            }
      }

      .hs-input:not([type=checkbox]):not([type=radio]){
        width: 100%;
      }

      textarea.hs-input {
        min-height: 100px;
        }

      .hs_submit {
          margin-top: 50px;
      }
      .hs-richtext
      {
          margin-top: 10px;
          margin-bottom: 10px;
      }
      fieldset {
          margin-bottom: 5px;
      }
      .field,
      .hs-form-field {
          font-family: @base-font-family;
          position: relative;
          margin-top: 10px;
          margin-bottom: 5px;
          input.text,
          input.title,
          textarea,
          select {
            display: block;
            width: 100%;
            max-width: 100% !important;
            color: initial;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            text-rendering: auto;
            &:focus {
              border: 1px solid gainsboro;
              box-shadow: 0 0 10px gainsboro;
            }
            &.input-validation-error {
              border: 2px solid @novicell-color;
              background-color: transparent;
              &:after {
                content: "✖";
                color: @novicell-color;
                font-size: 18px;
                display: block;
                position: relative;
              }
            }
        }
        select {
          padding: 0px;
          border: none;
          &:focus {
            outline: ridge;
          }
            option {
                &:hover {
                    background-color: @novicell-color;
                    color: @color-white;
                }
            }
        }
    }

    input[type=checkbox] + span {
        font-weight:normal;
        text-align: justify;
        color: #FFF;
    }
    input[type=checkbox]
    {
        border:none;
        margin-right: 3px;
    }
      .hs-error-msgs
      {
          font-size:14px;
          color:#620022;
          list-style:none;
          padding-left: 0px;

      }
    .input ul
    {
        font-weight:normal;
        padding-left: 0px;
    }
    .input li {
        list-style:none;
        text-decoration:none;
    }


    input[type=submit] {
        display: inline-block;
        margin-bottom: 0;
        font-weight: 300;
        font-family: @base-font-family;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -ms-touch-action: manipulation;
        user-select: none;
        position: relative;
        overflow: hidden;
        transition: background .2s;
        padding: 6px 50px;
        color: #ffffff;
        background-image: none;
        background: #7c0435;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0 4px 0 0 #620022;
        letter-spacing: normal;
        word-spacing: normal;
        text-overflow: ellipsis;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        text-decoration: none;
        text-align: center;
    }
    input.text,
    input.title,
    textarea,
    select {
        max-width: 100% !important;
        display: block;
        width: 100%;
        &:focus {
            outline: none;
        }
        &.input-validation-error {
            border: 2px solid @novicell-color;
            background-color: transparent;
            &:after {
            content: "✖";
            color: @novicell-color;
            font-size: 18px;
            display: block;
            position: relative;
            }
        }
    }
    select {
        option {
            &:hover {
                background-color: @novicell-color;
                color: @color-white;
            }
        }
    }
}

/* Act-On Form  */
.ao-form {
    margin-bottom:15px;

    div[class*=-column-], div[class*=-columns-], div[class^=-column-], div[class^=-columns-] {
        padding-top:0px !important;
        padding-bottom:0px !important;
    }


}

/* Form predefined styles*/

/* Form Style 1 */

.form-style-1 div:not(.checkbox) > label {
    display: none !important;
}

.form-style-1 input:not(.btn) {
    color: #000;
}

.form-style-1 input.input-validation-error {
    background-color: #FFF !important;
}

/* Form Style 1 for red background*/

.form-style-1.redbg span.field-validation-error > span {
    color: #fff957 !important;
}

.form-style-1.redbg input.input-validation-error {
    border-color: #fff957 !important;
}

.form-style-1.redbg input[type=submit] {
    background-color: #510035;
    box-shadow: 0 4px 0 0 #480931;
}

.form-style-1.redbg input[type=submit]:hover {
    background-color: #950034;
}

.legal-consent-container{
  margin-top: 50px;
}
