.filterable-text-pages-grid {
  &__filters-wrapper,
  &__content {
    box-sizing: border-box;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  &__filters-wrapper {
    justify-content: flex-start;
    margin: 0 0 25px;

    & .simple-nice-dropdown {
      max-width: 270px;
      margin-right: 25px;

      &__input {
        width: 100%;
        padding: 7px 12px 8px;
        background-color: #fff;
        font-weight: bold;
      }

      &:not(.is-selected) {
        & .simple-nice-dropdown__input {
          color: #646464;
          font-weight: normal;
        }
      }
    }

    @media (max-width: @screen-ms) {
      justify-content: center;
      margin: 0 0 15px;
      & .simple-nice-dropdown {
        margin: 0 0 15px;
      }
    }
  }

  & .grid-item {
    display: none;
    padding: 15px !important;

    &.visible {
      display: block;
    }

    &__inner {
      display: block;
      padding: 15px;
      box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      background-color: #fff;
      height: 100%;

      &--icon {
        text-align: center;
        padding: 20px 0;
        max-height: 125px;
      }

      &--image {
        width: 100%;
        height: 125px;
        object-fit: cover;
        display: block;
      }

      &--title {
        margin-top: 15px;
        font-size: 18px;
        font-weight: bold;
      }

      &--description {
        margin-top: 15px;
        font-size: 15px;
      }
    }
  }
}
