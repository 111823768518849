// Styles for the footer

#footer {
  font-family: @secondary-font-family;
  color: white;
  -webkit-font-smoothing: antialiased;

  @media (max-width: @screen-sm) {
    -moz-osx-font-smoothing: grayscale;
  }

  & > div {
    /*background: url(/dist/images/footer-bg.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;*/
  }

  h2 {
    color: #fff;
    margin-bottom: 20px;
    font-family: @secondary-font-family;
    font-weight: bold;
  }

  .map-text-color p {
    color: #000;
    font-size: 30px;
  }

  .row {
    padding: 0;

    @media (max-width: @screen-sm) {
      height: 267px;
    }
  }

  .map-box {
    background: radial-gradient(#afafaf, #fff 86%);
    height: ~'calc(100vh - 94px)';

    @media (max-width: @screen-sm) {
      height: auto;
      background: none;
    }

    @media (min-width: @screen-ulg-min) {
      height: ~'calc(115vh - 94px)';
    }
  }

  p {
    font-family: @secondary-font-family;
    margin: 0;
    font-size: 16px;
  }

  a {
    color: white;
    text-decoration: underline;
    padding-right: 0;
  }

  .icon {
    color: #c80046;
    margin-right: 25px;
    height: 55px;
    width: 57px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .social-icons {
    width: 450px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 15px;
    text-align: center;

    @media (max-width: @screen-sm) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      left: 0;
      margin-top: 50px;
      margin-bottom: 50px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .social-icons svg.icon {
    height: 40px;
    width: 40px;
  }

  .social-icons-box {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 36px;
    margin-top: 36px;
    height: 50px;

    &.social-icons {
      position: relative;
      width: 309px;
      margin: auto;
    }
  }

  .footer-info-box {
    text-align: center;
    position: relative;
    color: white;
    z-index: 6;
  }

  .footer-box {
    height: 432px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (min-width: @screen-lg) {
      height: 550px;
    }

    @media (min-width: @screen-md) {
      width: 20%;

      /* &--contact {
                    width: 40%;
                }
                */
    }

    &__bgImage {
      z-index: 1;
      filter: url(#duotone_nvcl);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      -webkit-transform-origin: 0 100%;
      transform-origin: 0 100%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:hover {
      .footer-box__bgImage {
        -ms-transform: scale(1.01); /* IE 9 */
        -webkit-transform: scale(1.01); /* Safari 3-8 */
        transform: scale(1.02);
        z-index: 5;
        box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.4),
          0 6px 20px 0 rgba(0, 0, 0, 0.4);
      }
    }

    .icon {
      color: #fff;
      margin-bottom: -7px;
      height: 23.95px;
      width: 21.62px;
      margin-left: 5px;
    }
  }

  .map-pins {
  }

  .footer-map-container {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-image: url('/dist/images/map-pins.png?v=6');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;

    @media (max-width: @screen-sm) {
      background-image: none;
    }

    @media (min-width: @screen-wlg) {
      width: 100%;
      max-width: 100%;
      background-position: 100% 50%;
      background-repeat: no-repeat;
    }
  }

  #offices {
    background-color: @novicell-color;
    float: left;
    width: 100%;
  }

  .map-text {
    position: absolute;
    margin-left: 23%;

    @media (min-width: 2350px) {
      margin-left: 33%;
    }

    @media (min-height: 800px) {
      margin-top: 10%;
    }

    @media (max-width: @screen-sm) {
      display: none;
    }
  }

  .footer-address {
    font-size: 20px;
    line-height: 25px;
  }

  .contact-info {
    h2 {
      font-family: @secondary-font-family;
      font-size: 22px;
      font-weight: 300;
    }
  }

  .contact-phone {
    float: right;
    text-align: right;
    font-weight: 300;
    padding-right: 0;

    p {
      margin-top: 10px;
      font-size: 22px;
    }

    @media (max-width: @screen-md) {
      float: left;
      text-align: left;
      margin-top: 30px;
      width: 100%;
    }
  }

  .row.clearfix {
    //padding: 60px 0;
  }

  .nc-grid-pagelist__page-name {
    font-family: @secondary-font-family;
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 10px;
  }

  .nc-grid-socialbuttons {
    text-align: right;
    margin-top: 10px;
    float: right;
    width: 100%;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
    }

    a {
      margin: 5px;
    }
  }

  .nc-grid-postaladdress {
    padding: 0 15px;

    h3 {
      font-size: 17px;
    }

    address {
      font-size: 16px;
      font-weight: 100;
      font-style: normal;
    }
  }

  span[property='addressCountry'] {
    display: block;
  }

  .footer-mail-tip {
    position: relative;
    border: 2px solid rgba(250, 250, 250, 30%);
    font-size: 12px;
    color: rgb(200, 0, 70);
    background-color: #fff;
    margin-top: 14px;
    border-radius: 4px;
    transition: opacity 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0;
    height: 0;
    margin: 0;

    &--active {
      opacity: 1;
      margin-top: 14px;
      height: auto;
    }
  }

  .footer-mail-tip:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 50%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    margin-left: -9px;
    margin-top: -15px;
  }

  .footer-mail {
    &-mobile {
      display: none;

      @media (max-width: @screen-md) {
        display: block;
      }
    }

    &-desk {
      display: block;
      cursor: pointer;

      @media (max-width: @screen-md) {
        display: none;
      }
    }
  }
}

.footer-offices {
  &.ie {
    .footer-box {
      &__bgImage {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: rgba(200, 0, 70, 0.8);
        }
      }

      &:hover {
        .footer-box__bgImage {
          &:after {
            /*background-color: transparent;*/
          }
        }
      }
    }
  }
}
