﻿/* Grid Page Header
********************************************************************/
.grid-pageheader {
}
/* Grid Section Header
********************************************************************/
.grid-sectionheader {
}
/* Grid Social Buttons
********************************************************************/
.nc-grid-socialbuttons {
}
/* Grid Postal Address
********************************************************************/
.nc-grid-postaladdress {
}
/* Grid Page List
********************************************************************/
.nc-grid-pagelist {
    a {
        text-decoration: underline;
        font-size: 16px;

        &:hover {
            text-decoration: none;
        }
    }
}
/* Grid News List
********************************************************************/
.nc-grid-newslist {
    margin: 0px auto;
    margin-top: 3rem;
    margin-bottom: 3rem;
    max-width: 90%;

    .owl-next {
        float: right;
        pointer-events: all;
        position: relative;
        right: -70px;

        @media (max-width:@screen-sm) {
            right: 0px;
        }
    }

    .owl-prev {
        float: left;
        pointer-events: all;
        position: relative;
        left: -70px;

        @media (max-width:@screen-sm) {
            left: 0px;
        }
    }

    .owl-nav {
        .owl-next,
        .owl-prev {
            svg {
                fill: #a5a5a5;
            }
        }

        &.disabled {
            display: none;
        }
    }

    .owl-dots {
        .owl-dot {
            display: inline-block;
            width: 7px;
            border-radius: 7px;
            height: 7px;
            background-color: @color-gray;
            margin: 10px 10px;

            &.active {
                background-color: @novicell-color;
            }
        }

        &.disabled {
            display: none;
        }
    }

    .owl-stage-outer {
        padding: 15px;
        margin-left: -15px;
        margin-right: -15px;
    }
}

.nc-grid-newslist__publishedDate {
    color: #a5a5a5;
    font-size: 14px;
    text-transform: uppercase;
}

.nc-grid-slideshow-image, .carousel-image {
    background-color: @color-white;
    min-height: 315px;
    -webkit-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
    -moz-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
    box-shadow: -2px 3px 19px 0px rgba(194,194,194,1);
    border-radius: 10px;

    a {
        text-decoration: none;
        color: black;
    }

    figure {
        height: 100%;

        img {
            height: 150px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    figcaption {
        padding: 20px;
        position: relative;

        h1,
        h2 {
            font-family: @base-font-family;
            font-size: 18px;
            font-weight: normal;
        }
    }
}

/* Grid Slideshow
********************************************************************/
.nc-grid-slideshow {
}
/* Grid Slideshow Inline
********************************************************************/
.nc-grid-slideshowinline {
    &:extend(.nc-grid-newslist);

    .owl-next {
        float: right;
        pointer-events: all;
        position: relative;
        right: -70px;

        @media (max-width:@screen-sm) {
            right: 0px;
        }
    }

    .owl-prev {
        float: left;
        pointer-events: all;
        position: relative;
        left: -70px;

        @media (max-width:@screen-sm) {
            left: 0px;
        }
    }

    .owl-nav {
        .owl-next, .owl-prev {
            svg {
                fill: #a5a5a5;
            }
        }

        &.disabled {
            display: none;
        }
    }
}

.picture__background-image {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    width: 80px;
    height: 80px;
    border-radius: 80px;
    border: 4px solid white;
    position: absolute;
    top: -45px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.nc-grid-slideshowinline__publishedDate {
    &:extend(.nc-grid-newslist__publishedDate);
}
/* Grid Embed Media
********************************************************************/
.nc-grid-embedmedia {
    .embed-preview-image {
        cursor: pointer;
    }

    .embed-media-item {
        display: none;
    }
}

/* Cases component
********************************************************************/

.case,
.sm-caption-case,
.md-caption-case,
.lg-caption-case {
    color: grey;

    figure {
        position: relative;
        overflow: hidden;

        &:hover {
            cursor: pointer;
        }

        &:hover > figcaption {
            padding-bottom: 25px;
        }

        &:hover > figcaption .more-info {
            opacity: 1;
            max-height: 500px;
            -webkit-transition: max-height 0.5s ease-in;
            -moz-transition: max-height 0.5s ease-in;
            -o-transition: max-height 0.5s ease-in;
            transition: max-height 0.5s ease-in;
        }

        .figure__bg {
            background: black;
            background: -webkit-linear-gradient(transparent, black);
            background: -o-linear-gradient(transparent, black);
            background: -moz-linear-gradient(transparent, black);
            background: linear-gradient(transparent, black);
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
            opacity: 0.7;
        }

        .icon-diagonal-arrow {
            display: none;
            position: absolute;
            bottom: 30px;
            right: 20px;
            fill: white;
            z-index: 2;
        }
    }

    img {
        width: 100% !important;
        margin: 0;
        display: initial;
        position: relative;
    }

    figcaption {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 2;
        color: white;
        width: 100%;
        padding: 0 20px;
        padding-bottom: 15px;
        -webkit-transition: padding 0.2s ease-in;
        -moz-transition: padding 0.2s ease-in;
        -o-transition: padding 0.2s ease-in;
        transition: padding 0.2s ease-in;

        @media (max-width:@screen-sm) {
            display: block;
            text-shadow: 1px 2px 0 #333;
        }

        h1,
        h2 {
            font-family: @secondary-font-family;
            font-size: 18px;
            margin-bottom: 5px;
            margin-top: 0;
            font-weight: 600;
        }

        .more-info {
            font-size: 14px;
            font-family: @secondary-font-family;
            font-weight: 100;
            opacity: 0;
            max-height: 0;
            -webkit-transition: max-height 0.2s ease-out;
            -moz-transition: max-height 0.2s ease-out;
            -o-transition: max-height 0.2s ease-out;
            transition: max-height 0.2s ease-out;
            bottom: 5px;

            svg {
                position: relative;
                top: 3px;
                margin-left: 5px;
            }
        }
    }
}

#cases {
    margin: 3rem 0;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .case {
        margin: 0;
        margin-bottom: 30px;
        float: left;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: 300px;

        figure, img, .figure__bg {
            height: 300px;
            min-height: 300px !important;
        }
    }
}

.sm-caption-case {
    height: 200px;

    figure, img, .figure__bg {
        height: 200px;
        min-height: 200px !important;
    }
}

.md-caption-case {
    height: 250px;

    figure, img, .figure__bg {
        height: 250px;
    }
}

.lg-caption-case {
    height: 300px;

    figure, img, .figure__bg {
        height: 300px;
    }
}

.case__subtitle {
    font-family: @secondary-font-family;
    font-weight: 300;
    font-size: 16px;
    margin-top: 0;
    padding-right: 10px;
}

.case__description {
    font-family: @secondary-font-family;
    font-weight: 100;
    font-size: 14px;
    margin-top: 0;
    padding-right: 10px;
}

/* Animated Numbers
********************************************************************/

.number {
    margin-bottom: 3rem;
    text-align: center;
}

.animated__number {
    font-family: @secondary-font-family;
    visibility: hidden;
    opacity: 0 !important;
    color: @novicell-color;
    text-align: center;
    font-weight: bolder;
    font-size: 55px;
    background: -webkit-linear-gradient(@novicell-color, #fda981);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: opacity 0.5s, visibility 0.5s;

    &.in-view {
        display: inline-block;
        visibility: visible;
        opacity: 1 !important;
        transition: opacity 0.5s, visibility 0.5s;
    }
}

.description__number {
    text-align: center;
}

/* Icon Info
********************************************************************/
.info-icon-block {
    text-align: center;
    margin-top: 2rem;

    svg {
        display: block;
        margin: 0px auto;
    }
}

/* Testimonial
********************************************************************/
.testimonial {
    margin: 3rem 0;
    float: left;
    width: 100%;

    &__picture {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        width: 200px;
        height: 200px;
        border-radius: 100px;
        margin-left: 20px;
        position: relative;

        &-container {
            position: relative;

            .icon {
                position: absolute;
                right: 40px;
                top: 0;
                width: 40px;
                height: 40px;

                @media (max-width:@screen-sm) {
                    position: relative;
                    right: auto;
                    top: auto;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    .description {
        p {
            margin-top: 0;
        }
    }
}

/* Employee
********************************************************************/

.nc-grid-employee {
    .employee {
        text-align: center;
        padding: 3rem 0;

        &__picture {
            border-radius: 88px;
            width: 175px;
            height: 175px;
        }

        &__name {
            font-size: 22px;
        }

        &__position {
            font-size: 15px;
            font-weight: 900;
        }

        &__description {
            max-width: 100%;
            width: 100%;
        }

        &__telephone {
            font-family: @secondary-font-family;
            font-size: 32px;
        }
    }
}

/* last events list
********************************************************************/

.nc-grid-eventlist {
    .event {
        text-align: center;
        padding: 0;
        border-radius: 10px;
        background: @color-white;
        -webkit-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
        -moz-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
        box-shadow: -2px 3px 19px 0px rgba(194,194,194,1);

        &__image {
            width: 100%;
            height: 300px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;

            &__container {
                padding: 0;
            }
        }

        &__nextSeminar {
            font-size: 16px;
            text-transform: uppercase;
            color: @color-gray;
        }

        .button {
            margin: 10px 0;
        }
    }
}


/* Contact Card
********************************************************************/

.contact-card {
    -webkit-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
    -moz-box-shadow: -2px 9px 19px 0px rgba(194,194,194,1);
    box-shadow: -2px 3px 19px 0px rgba(194,194,194,1);
    border-radius: 10px;
    margin-bottom: 50px;
    width: 100%;
    text-align: center;
    overflow: hidden;

    header {
        img {
            width: 100%;
            height: 300px;
        }

        h2 {
            font-family: @base-font-family;
            font-weight: 600;
            font-size: 30px;
            margin: 30px 0;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    &__content {
        padding: 0 80px;
        font-family: @base-font-family;
        font-weight: 300;

        address {
            font-style: normal;
        }

        .separator {
            width: 80px;
            display: block;
            margin: 0px auto;
            margin-top: 20px;
            margin-bottom: 20px;
            height: 2px;
            background: @novicell-color;
        }

        .contact-info {
            margin-top: 20px;
            margin-bottom: 40px;

            p {
                margin: 0;
            }
        }
    }
}

/* Image Slider
********************************************************************/

.nc-grid-imageSlider {
    position: relative;

    .owl-nav {
        .owl-next,
        .owl-prev {
            svg {
                fill: #a5a5a5;
            }
        }

        &.disabled {
            display: none;
        }
    }

    .owl-dots {
        position: absolute;
        margin: auto;
        bottom: 20px;
        left: 0;
        right: 0;

        .owl-dot {
            display: inline-block;
            width: 10px;
            border-radius: 10px;
            height: 14px;
            background-color: @color-white;
            margin: 10px 10px;
            border: 1px solid @color-white;

            &.active {
                background-color: @novicell-color;
            }
        }

        &.disabled {
            display: none;
        }
    }
}
/* Fact Box
********************************************************************/

.fact-box {
    position: relative;
    width: 100%;

    &__content {
        background-color: @novicell-color;
        color: @color-white;
        font-family: @secondary-font-family;
        font-size: 20px;
        font-weight: 300;
        font-style: italic;
        padding: 35px;
        border-radius: 10px;
    }

    &__user {
        position: relative;
        left: 15%;
        top: -15px;
        float: left;

        &__picture {
            float: left;

            picture {
                float: left;

                img {
                    filter: grayscale(100%);
                    -webkit-filter: grayscale(100%);
                    border-radius: 40px;
                    border: 4px solid white;
                    display: inline-block;
                }
            }
        }

        &__info {
            float: left;
            height: 80px;
            position: relative;
            top: 0;
            padding: 15px 10px;

            p {
                margin: 0;
            }
        }
    }
}

/* Highlight Card
********************************************************************/

.highlight-card {
  margin-bottom: 30px;

    h3.card-title {
        @media (min-width:@screen-sm) {
            display: inline-block;
        }

        font-weight: 900;
    }

    .grid-highlight-card {
        border: 1px solid gainsboro;
        padding: 40px;

        @media (min-width:@screen-sm) {
            min-height: 400px;
            max-height: 400px;
        }

        svg {
            display: block;

            @media (min-width:@screen-sm) {
                display: inline-block;
                position: relative;
                top: 20px;
                margin-right: 30px;
            }

            width: 50px;
            height: 50px;
        }

        .description {
            @media (min-width:@screen-sm) {
                padding-left: 90px;
                padding-right: 50px;
            }

            color: #6d6c6c;
            font-family: @base-font-family;
            font-size: 17px;

            p {
                margin: 0;
                line-height: 25px;
            }

            a {
                color: @novicell-color;
                text-decoration: none;

                &:hover {
                    color: darken(@novicell-color, 25%);
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 5px;
                margin-top: 5px;

                li {
                    padding-left: 1.3em;

                    &:before {
                        content: ">\00a0";
                        display: inline-block;
                        margin-left: -3.1em;
                        width: 1.3em;
                        color: @novicell-color;
                        font-size: 12px;
                        line-height: 25px;
                        position: relative;
                        top: -2px;
                        font-family: cursive;
                    }
                }
            }
        }

        &__link {
          float: left;
          width: 100%;

          &:hover {
              cursor: pointer;
              .box-shadow(0 0 35px #ddd);
          }

        }
    }

    div[class*="col--"] {
        padding: 0;
    }
}

/* Standard padding on column rows
********************************************************************/

div[class^="-column-"],
div[class*="-column-"],
div[class^="-columns-"],
div[class*="-columns-"] {
    padding: 50px 15px;

    @media (min-width:@screen-md) {
        padding: 100px 15px;
    }
}

/* Background rows styles
********************************************************************/
div[data-background-image] {
    // opacity: 0;
    -webkit-transition: opacity 0.5s ease-in;
    -moz-transition: opacity 0.5s ease-in;
    -o-transition: opacity 0.5s ease-in;
    transition: opacity 0.5s ease-in;
    position: relative;
    opacity: .999;
    overflow: hidden;
}

/* Grid Themes
********************************************************************/
.theme {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &1 {
        &:extend(.theme);
        color: @color-text;
        background-color: @color-longfarm;

        h1, h2, h3, p {
            color: @color-text;
        }

        svg {
            fill: @color-text;
        }
    }

    &2 {
        &:extend(.theme);
        background-color: @color-spearmint;
        color: @color-text;

        h1, h2, h3, p {
            color: @color-text;
        }

        svg {
            fill: @color-white;
        }
    }

    &3 {
        &:extend(.theme);
        color: @color-white;
        background-color: @new-novicell-color;

        h1, h2, h3, p {
            color: @color-white;
        }

        svg {
            fill: @color-white;
        }

        .nc-grid-richtexteditor {

            a {
                text-decoration: underline;
                color: @color-white;

                &:hover {
                    text-decoration: none;
                    color: @color-gray;
                }
            }
        }


    }

    &4 {
        &:extend(.theme);
         background-color: @color-berry;
         color: @color-white;
         padding: 7rem 0;
    }

     &5 {
        &:extend(.theme);
         background-color: @color-plum;
         color: @color-white;

            h1, h2, h3, p {
            color: @color-white;
        }
    }

    &-lightgrey {
        &:extend(.theme);
        background-color: @color-gray-light-2;
    }

    &-black {
        &:extend(.theme);
        background-color: #222222;
        color: white;

        svg {
            fill: @color-white;
        }
    }
}

/* Grid Themes
********************************************************************/

.thiner-font {
    font-family: @secondary-font-family;
    font-weight: 100;
}

.light-font {
    font-family: @secondary-font-family;
    font-weight: 300;
}

.smaller-hero-banner {
  font-size: 45px;
  font-weight: 700;
  line-height: 60.5px;
}

.black-font {
    font-family: @secondary-font-family;
    font-weight: 900;
}

.remove-link-styles {
    text-decoration: none !important;
    color: #363636;

    &:hover {
        text-decoration: none !important;
        color: #363636;
    }

    a {
        text-decoration: none !important;
        color: #363636;

        &:hover {
            text-decoration: none !important;
            color: #363636;
        }
    }
}

.nc-grid-richtexteditor {
    padding: 0 15px 0 15px;

    a {
        text-decoration: none;
        color: @new-novicell-color;

        &:hover {
            text-decoration: none;
            color: @color-gray;
        }
    }

    .button {
        a {
            color: @color-white;
        }

        &--light,
        &--ghost {
            a {
                color: @new-novicell-color;
            }
        }
    }

    span {
        &:not(.button) {
            a {
                &:not(.button) {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                        color: @color-gray;
                    }
                }
            }
        }
    }
}

/*Video
*********************************************************************/
.case-grid-video-background-container {
    position: relative;
}

#case-grid-video {
    min-height: 625px;
    margin-top: -625px;

    @media (max-width:@screen-md) {
        min-height: 400px;
        margin-top: -400px;
    }

    background-color: #FFF;

    .video-container {
        position: relative;
        width: 100%;
    }

    .overlayBox {
        height: 625px;

        @media (max-width:@screen-md) {
            height: 400px;
        }

        position: absolute;
        z-index: 3;
        display: none;
        width: 100%;
        margin: 0 auto;
        top: 0;
        background-color: #000;
        overflow: hidden;
    }

    .overlayContent {
        width: 100%;
        height: 100%;
        text-align: right;
        max-width: 1111px;
        margin: 0 auto;
    }

    .closeLink {
        position: relative;
        top: 50px;
        right: 12px;
        z-index: 1;
        font-size: 24px;
        color: #FFF;
        padding: 15px;
    }

    .youtube-container {
        margin-top: -34px;
        margin-bottom: 0;
        padding-bottom: 56.25%;
        position: relative;
        width: 100%;
        height: 0;
    }

    .video-tag {
        padding-top: 175px;

        @media (max-width:@screen-md) {
            padding-top: 75px;
        }

        @media (max-width:@screen-sm) {
            padding-top: 50px;
        }

        color: #FFF;
        font-size: 28px;
        text-align: center;
        z-index: 2;
        position: relative;
    }

    .video-description {
        color: #FBF9FA;
        font-size: 18px;
        text-align: center;
        margin-bottom: 25px;
        z-index: 2;
        position: relative;
        padding: 0px 25px;
    }

    .video-play-button {
        display: block;
        margin: 0 auto;
        opacity: 1;
        z-index: 2;
        position: relative;
        color: white;
    }
}

.case-grid-video-background-container {
    .case-grid-video-background {
        height: 625px;

        @media (max-width:@screen-md) {
            height: 400px;
        }

        background-position-x: center;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
    }

    .case-grid-video-background-brightness {
        height: 625px;

        @media (max-width:@screen-md) {
            height: 400px;
        }

        display: block;
        position: absolute;
        width: 100%;
        top: 0;
        background-color: rgba(0,0,0,.65);
        z-index: 0;
    }

    .youtube-container iframe {
        padding: 0;
    }
}

.youtube-container iframe {
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

/* Grid fixes
********************************************************************/

.container-fluid {
    padding-left: 15px;
    padding-right: 15px;

    .row {
        div[class*="col--"] {
            padding: 0;
        }
    }
}

.padding-on-mobile {
    @media (max-width:@screen-sm) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

/* Social buttons
********************************************************************/
.social-buttons {
    padding: 10px 0;
    text-align: center;

    ul {
        li.upHover {
            transition: transform 1s ease-out;
            &:hover {
                transform: translate(0, -10px);
            }
        }
        li {
            display: inline-block;
            transition: transform 1s ease-out;

            a {
                padding: 5px;
                transition: opacity ease 0.5s;

                img {
                    width: 30px;
                    height: auto;
                }

                &:hover {
                    opacity: 0.7;
                }
            }

        }
    }
}

/* Novicell Fashion Device
********************************************************************/

.fashion-media-device-block {
  position: relative;

  .icon {
    width: 40px;
    height: 40px;
  }

  div[class*="col--"] {
    position: initial;
  }

}

.nc-grid-fashionDevice {
  position: initial;

  video.video-background {
    @media (max-width:@screen-ms) {
            display: block;
        }
    }

  &__title {
    font-weight: bold;
  }

  &__Iphone {
    width: 285px;
    height: 593px;
    position: relative;
    display: block;
    margin: auto;
    pointer-events: none;

    @media (max-width:@screen-md) {
      margin-top: 30px;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      background: url(/dist/images/iphone-front.png);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .nc-grid-fashionDevice__image,
    img,
    .video-background__container {
      width: ~"calc(100% - 25px)";
      height: ~"calc(100% - 140px)";
      object-fit: fill;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-color: black;

      .video-background {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: all;
      }
    }
    .video-controls {
      position: absolute;
      display: block;
      margin: auto;
      width: 220px;
      height: 40px;
      max-width: 90%;
      left: 0;
      right: 0;
      top: 0px;
      bottom: 0;
      z-index: 5;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;

      button {
        background: transparent;
        -webkit-appearance: none;
        border: none;
        pointer-events: all;
        z-index: 2;
        position: relative;

        &:active,
        &:focus {
          outline: 0;
        }

        .icon {
          fill: rgba(200, 0, 70, 0.8);
        }

        &:hover {
          cursor: pointer;
        }

        .unmute-icon {
          transition: opacity 0.3s;
        }

        .mute-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
          position: relative;
          left: -5px;
        }

        .play-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
        }

        .pause-icon {
          transition: opacity 0.3s;
        }

        &.play-pause {
          pointer-events: none;
        }
      }
    }

    &:hover {
      .video-controls {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &.halfiphone {
    padding-top: 150px;
    padding-bottom: 150px;

    @media (max-width:@screen-md) {
      padding-top: 50px;
      padding-bottom: 300px;
    }
  }

  &__HalfIphone {
    width: 350px;
    height: 610px;
    position: absolute;
    display: block;
    margin: auto;
    bottom: 0;
    right: 20%;
    overflow: hidden;
    pointer-events: none;

    @media (max-width:@screen-md) {
      width: 200px;
      height: 340px;
      left: 0;
      right: 0;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      background: url(/dist/images/Half_iphone-front.png);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .nc-grid-fashionDevice__image,
    img,
    .video-background__container {
      width: ~"calc(100% - 25px)";
      height: ~"calc(100% - 90px)";
      object-fit: fill;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 90px;
      bottom: 0;
      margin: auto;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-color: black;

      .video-background {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        pointer-events: all;
      }

      @media (max-width:@screen-md) {
        width: ~"calc(100% - 15px)";
        height: ~"calc(100% - 50px)";
        top: 50px;
      }
    }
    .video-controls {
      position: absolute;
      display: block;
      margin: auto;
      width: 220px;
      height: 40px;
      max-width: 90%;
      left: 0;
      right: 0;
      top: 90px;
      bottom: 0;
      z-index: 5;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;

      button {
        background: transparent;
        -webkit-appearance: none;
        border: none;
        pointer-events: all;
        z-index: 2;
        position: relative;

        &:active,
        &:focus {
          outline: 0;
        }

        .icon {
          fill: rgba(200, 0, 70, 0.8);
        }

        &:hover {
          cursor: pointer;
        }

        .unmute-icon {
          transition: opacity 0.3s;
        }

        .mute-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
          position: relative;
          left: -5px;
        }

        .play-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
        }

        .pause-icon {
          transition: opacity 0.3s;
        }

        &.play-pause {
          pointer-events: none;
        }
      }
    }

    &:hover {
      .video-controls {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &__Macbook {
    width: 1039px;
    height: 560px;
    position: relative;
    display: block;
    margin: auto;
    margin-top: 30px;
    max-width: 100%;
    pointer-events: none;

    @media (max-width:@screen-lg) {
      width: 610px;
      height: 320px;
    }

    @media (max-width:@screen-sm) {
      width: 425px;
      height: 220px;
    }

    @media (max-width: @screen-ms) {
      width: 300px;
      height: 220px;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      background: url(/dist/images/macbook-white.png);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;

      @media (max-width: @screen-ms) {
        height: ~"calc(100% - 60px)";
      }
    }

    .nc-grid-fashionDevice__image,
    img,
    .video-background__container {
      width: ~"calc(100% - 315px)";
      height: ~"calc(100% - 105px)";
      //object-fit: fill;
      position: relative;
      display: block;
      z-index: 1;
      top: 37px;
      margin: 0 auto;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-color: black;

      @media (max-width:@screen-lg) {
        width: ~"calc(100% - 180px)";
        height: ~"calc(100% - 52px)";
        top: 16px;
      }
      @media (max-width:@screen-sm) {
        width: ~"calc(100% - 105px)";
        height: ~"calc(100% - 34px)";
        top: 10px;
      }
      @media (max-width: @screen-ms) {
        width: ~"calc(100% - 90px)";
        height: ~"calc(100% - 90px)";
        top: 40px;
      }

      .video-background {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: all;
      }

    }

    .video-controls {
      position: absolute;
      display: block;
      margin: auto;
      width: 220px;
      height: 40px;
      max-width: 90%;
      left: 0;
      right: 0;
      top: -30px;
      bottom: 0;
      z-index: 5;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;

      button {
        background: transparent;
        -webkit-appearance: none;
        border: none;
        pointer-events: all;
        z-index: 2;
        position: relative;

        &:active,
        &:focus {
          outline: 0;
        }

        .icon {
          fill: rgba(200, 0, 70, 0.8);
        }

        &:hover {
          cursor: pointer;
        }

        .unmute-icon {
          transition: opacity 0.3s;
        }

        .mute-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
          position: relative;
          left: -5px;
        }

        .play-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
        }

        .pause-icon {
          transition: opacity 0.3s;
        }

        &.play-pause {
          pointer-events: none;
        }
      }
    }

    &:hover {
      .video-controls {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &.halfmacbook {
    padding-top: 160px;
    padding-bottom: 160px;
    position: relative;

    @media (max-width:@screen-md) {
      padding-top: 50px;
      padding-bottom: 425px;
    }
  }

  &__HalfMacbook {
    width: 424px;
    height: 463px;
    position: absolute;
    right: 0;
    top: ~"calc(50% - 463px)";
    bottom: 0;
    display: block;
    margin: auto;
    pointer-events: none;

    @media (max-width:@screen-lg) {
      width: 524px;
      height: 563px;
    }

    @media (max-width:@screen-md) {
      width: 384px;
      height: 413px;
      bottom: 0px;
      top: auto;
    }

    @media (max-width:@screen-sm) {
      width: 324px;
      height: 363px;
      bottom: 0px;
      top: auto;
    }

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 2;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      background: url(/dist/images/Half_macbook-white.png);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    .nc-grid-fashionDevice__image,
    img,
    .video-background__container {
      width: ~"calc(100% - 130px)";
      height: ~"calc(100% - 90px)";
      object-fit: fill;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 34px;
      margin: auto;
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-color: black;

      @media (max-width:@screen-md) {
        width: ~"calc(100% - 105px)";
        height: ~"calc(100% - 82px)";
        top: 30px;
      }

      @media (max-width:@screen-sm) {
        width: ~"calc(100% - 98px)";
        height: ~"calc(100% - 70px)";
        top: 25px;
      }

      .video-background {
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        pointer-events: all;
      }

    }

    .video-controls {
      position: absolute;
      display: block;
      margin: auto;
      width: 220px;
      height: 40px;
      max-width: 90%;
      left: 150px;
      right: 0;
      top: -30px;
      bottom: 0;
      z-index: 5;
      text-align: center;
      opacity: 0;
      transition: opacity 0.5s;
      pointer-events: none;

      @media (max-width:@screen-sm) {
        left: 120px;
      }

      button {
        background: transparent;
        -webkit-appearance: none;
        border: none;
        pointer-events: all;
        z-index: 2;
        position: relative;

        &:active,
        &:focus {
          outline: 0;
        }

        .icon {
          fill: rgba(200, 0, 70, 0.8);
        }

        &:hover {
          cursor: pointer;
        }

        .unmute-icon {
          transition: opacity 0.3s;
        }

        .mute-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
          position: relative;
          left: -5px;
        }

        .play-icon {
          opacity: 0;
          width: 0;
          height: 0;
          transition: opacity 0.3s;
        }

        .pause-icon {
          transition: opacity 0.3s;
        }

        &.play-pause {
          pointer-events: none;
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      .video-controls {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }

  }
}

/* CTA Banner
********************************************************************/

.ncgrid-cta-banner{
  .button {
      margin-bottom: 30px;
  }

  &__title {
      margin-top: 30px;
      margin-bottom: 30px;
      text-align: center;
      &--small {
          text-align: left;
      }
  }

  &__content {
      padding: 15px;
      padding-left: 30px;
  }

  .ncgrid-cta-banner__imageContainer--twocolumns.col--xs-12.col--md-6 {
    padding: 15px;
  }
}

/* Novicell Responsive Utilities Classes
********************************************************************/

div[data-hide*="hidden-lg"] {
  @media (min-width:@screen-lg) {
    display: none;
  }
}

div[data-hide*="hidden-md"] {
  @media (min-width:@screen-md) and (max-width:@screen-lg) {
    display: none;
  }
}

div[data-hide*="hidden-sm"] {
  @media (min-width:@screen-sm) and (max-width:@screen-md) {
    display: none;
  }
}

div[data-hide*="hidden-xs"] {
  @media (min-width:@screen-xs) and (max-width:@screen-sm) {
    display: none;
  }
}
