/*
 * Main stylesheet
 *
 * This is where we make sure all stylesheets are combined properly and imported
 * Please refrain from writing any actual code in this file, other than imports
 */
/*!

PROJECT NAME

Proud authors:      www.novicell.dk
People behind:      Lars Hesselberg, @hesselberg

!*/
/**
 * Class notation:  BEM - https://css-tricks.com/bem-101/
 *
 * Quick example of BEM class notation:
 *
 * BLOCK:           breadcrumb
 * ELEMENT:         breadcrumb__link
 * MODIFIER:        breadcrumb__link—-active
 *
 *
 * So, for the markup it would look something like:
 *
 * <div class="breadcrumb">                                                      <---- BLOCK
 *     <a class="breadcrumb__link" href="#">Home</a>                             <---- ELEMENT below the BLOCK
 *     <span class="breadcrumb__link breadcrumb__link--active">Page</span>       <---- Also an ELEMENT, but to make it look active, we add the MODIFIER 'active'
 * </div>
 */
/* Settings
********************/
/**
 * Settings: Variables
 */
/* Colors
********************/
/* Layout
********************/
/* Typography
********************/
/* Grid
********************/
/* Media query breakpoints
********************/
/* Mixins
********************/
/**
 * Vendor prefixes
 */
/**
 * Base: Typography
 */
h1,
h2,
h3,
h4,
h5,
.h-style {
  font-family: "Averta", "Helvetica", "Arial", "sans-serif";
  font-weight: 900;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
  word-break: break-word;
}
h1,
.h-style.h1 {
  font-size: 30px;
}
@media (min-width: 768px) {
  h1,
  .h-style.h1 {
    font-size: 42px;
  }
}
@media (min-width: 1200px) {
  h1,
  .h-style.h1 {
    font-size: 55px;
  }
}
h2,
.h-style.h2 {
  font-size: 21px;
  font-weight: 300;
}
@media (min-width: 768px) {
  h2,
  .h-style.h2 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  h2,
  .h-style.h2 {
    font-size: 36px;
  }
}
h3,
.h-style.h3 {
  font-size: 18px;
  font-weight: 300;
}
@media (min-width: 768px) {
  h3,
  .h-style.h3 {
    font-size: 21px;
  }
}
@media (min-width: 1200px) {
  h3,
  .h-style.h3 {
    font-size: 24px;
  }
}
p.lead {
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  line-height: 1.4;
}
@media (min-width: 768px) {
  p.lead {
    font-size: 21px;
  }
}
@media (min-width: 1200px) {
  p.lead {
    font-size: 24px;
  }
}
p,
ul {
  margin-bottom: 20px;
  word-break: break-word;
}
p ~ h2,
p ~ .h-style.h2,
p ~ h3,
p ~ .h-style.h3 {
  margin-top: 36px;
}
html {
  /*h1, h2, h3, h4, h5 { opacity: 0; transition: opacity 0.3s; }*/
}
html.wf-active h1,
html.wf-inactive h1,
html.wf-active h2,
html.wf-inactive h2,
html.wf-active h3,
html.wf-inactive h3,
html.wf-active h4,
html.wf-inactive h4,
html.wf-active h5,
html.wf-inactive h5,
html.wf-active .h-style,
html.wf-inactive .h-style {
  opacity: 1;
}
/* Components
********************/
/**
 * Component: Accordion
 */
input.accordion-hidden {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
/* Accordion styles */
.accordions {
  border-radius: 8px;
  overflow: hidden;
}
.accordion {
  width: 100%;
  color: white;
  overflow: hidden;
}
.accordion-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: #C80046;
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}
.accordion-label:not(:last-child) {
  border-bottom: 1px solid grey;
}
.accordion-label:hover {
  background: #7C0435;
}
.accordion-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}
.accordion-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all .35s;
}
.accordion-content p {
  margin: 0;
}
.accordion-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}
.accordion-close:hover {
  background: #7C0435;
}
input:checked + .accordion-label {
  background: #7C0435;
}
input:checked + .accordion-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
input:checked ~ .accordion-content {
  max-height: 100vh;
  padding: 1em;
}
#footer {
  font-family: 'Averta', "Arial", "sans-serif";
  color: white;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 768px) {
  #footer {
    -moz-osx-font-smoothing: grayscale;
  }
}
#footer > div {
  /*background: url(/dist/images/footer-bg.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;*/
}
#footer h2 {
  color: #fff;
  margin-bottom: 20px;
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: bold;
}
#footer .map-text-color p {
  color: #000;
  font-size: 30px;
}
#footer .row {
  padding: 0;
}
@media (max-width: 768px) {
  #footer .row {
    height: 267px;
  }
}
#footer .map-box {
  background: radial-gradient(#afafaf, #fff 86%);
  height: calc(100vh - 94px);
}
@media (max-width: 768px) {
  #footer .map-box {
    height: auto;
    background: none;
  }
}
@media (min-width: 2150px) {
  #footer .map-box {
    height: calc(115vh - 94px);
  }
}
#footer p {
  font-family: 'Averta', "Arial", "sans-serif";
  margin: 0;
  font-size: 16px;
}
#footer a {
  color: white;
  text-decoration: underline;
  padding-right: 0;
}
#footer .icon {
  color: #c80046;
  margin-right: 25px;
  height: 55px;
  width: 57px;
  margin-left: 12px;
  margin-right: 12px;
}
#footer .social-icons {
  width: 450px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  margin-bottom: 15px;
  text-align: center;
}
@media (max-width: 768px) {
  #footer .social-icons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
#footer .social-icons svg.icon {
  height: 40px;
  width: 40px;
}
#footer .social-icons-box {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 36px;
  margin-top: 36px;
  height: 50px;
}
#footer .social-icons-box.social-icons {
  position: relative;
  width: 309px;
  margin: auto;
}
#footer .footer-info-box {
  text-align: center;
  position: relative;
  color: white;
  z-index: 6;
}
#footer .footer-box {
  height: 432px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (min-width: 1200px) {
  #footer .footer-box {
    height: 550px;
  }
}
@media (min-width: 992px) {
  #footer .footer-box {
    width: 20%;
    /* &--contact {
                    width: 40%;
                }
                */
  }
}
#footer .footer-box__bgImage {
  z-index: 1;
  filter: url(#duotone_nvcl);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
#footer .footer-box:hover .footer-box__bgImage {
  -ms-transform: scale(1.01);
  /* IE 9 */
  -webkit-transform: scale(1.01);
  /* Safari 3-8 */
  transform: scale(1.02);
  z-index: 5;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
#footer .footer-box .icon {
  color: #fff;
  margin-bottom: -7px;
  height: 23.95px;
  width: 21.62px;
  margin-left: 5px;
}
#footer .footer-map-container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-image: url('/dist/images/map-pins.png?v=6');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 768px) {
  #footer .footer-map-container {
    background-image: none;
  }
}
@media (min-width: 1920px) {
  #footer .footer-map-container {
    width: 100%;
    max-width: 100%;
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
}
#footer #offices {
  background-color: #c80046;
  float: left;
  width: 100%;
}
#footer .map-text {
  position: absolute;
  margin-left: 23%;
}
@media (min-width: 2350px) {
  #footer .map-text {
    margin-left: 33%;
  }
}
@media (min-height: 800px) {
  #footer .map-text {
    margin-top: 10%;
  }
}
@media (max-width: 768px) {
  #footer .map-text {
    display: none;
  }
}
#footer .footer-address {
  font-size: 20px;
  line-height: 25px;
}
#footer .contact-info h2 {
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 22px;
  font-weight: 300;
}
#footer .contact-phone {
  float: right;
  text-align: right;
  font-weight: 300;
  padding-right: 0;
}
#footer .contact-phone p {
  margin-top: 10px;
  font-size: 22px;
}
@media (max-width: 992px) {
  #footer .contact-phone {
    float: left;
    text-align: left;
    margin-top: 30px;
    width: 100%;
  }
}
#footer .nc-grid-pagelist__page-name {
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 18px;
  font-weight: 100;
  margin-bottom: 10px;
}
#footer .nc-grid-socialbuttons {
  text-align: right;
  margin-top: 10px;
  float: right;
  width: 100%;
}
#footer .nc-grid-socialbuttons h2 {
  margin-top: 0;
  margin-bottom: 0;
  display: inline-block;
}
#footer .nc-grid-socialbuttons a {
  margin: 5px;
}
#footer .nc-grid-postaladdress {
  padding: 0 15px;
}
#footer .nc-grid-postaladdress h3 {
  font-size: 17px;
}
#footer .nc-grid-postaladdress address {
  font-size: 16px;
  font-weight: 100;
  font-style: normal;
}
#footer span[property='addressCountry'] {
  display: block;
}
#footer .footer-mail-tip {
  position: relative;
  border: 2px solid rgba(250, 250, 250, 0.3);
  font-size: 12px;
  color: #c80046;
  background-color: #fff;
  margin-top: 14px;
  border-radius: 4px;
  transition: opacity 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0;
  height: 0;
  margin: 0;
}
#footer .footer-mail-tip--active {
  opacity: 1;
  margin-top: 14px;
  height: auto;
}
#footer .footer-mail-tip:after {
  content: '';
  position: absolute;
  top: 4px;
  left: 50%;
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  margin-left: -9px;
  margin-top: -15px;
}
#footer .footer-mail-mobile {
  display: none;
}
@media (max-width: 992px) {
  #footer .footer-mail-mobile {
    display: block;
  }
}
#footer .footer-mail-desk {
  display: block;
  cursor: pointer;
}
@media (max-width: 992px) {
  #footer .footer-mail-desk {
    display: none;
  }
}
.footer-offices.ie .footer-box__bgImage:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(200, 0, 70, 0.8);
}
.footer-offices.ie .footer-box:hover .footer-box__bgImage:after {
  /*background-color: transparent;*/
}
#ncOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3300;
  background: rgba(0, 0, 0, 0.5);
  display: none;
  cursor: pointer;
}
#ncOverlay-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647;
  margin: auto;
  display: none;
}
.ncOverlay-inner {
  border-radius: 3px;
  margin: auto;
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
}
.ncOverlay-inner-scroll {
  overflow-y: auto;
  height: 100%;
  padding: 0 20px;
  position: relative;
}
.ncOverlay-close {
  top: -20px;
  right: -16px;
  cursor: pointer;
  position: absolute;
  color: white;
}
.ncOverlay-close .icon {
  width: 16px;
  height: 16px;
}
/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}
/*
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
  text-align: center;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-prev,
.owl-next {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: transparent;
  outline: 0;
  border: 0;
}
.owl-prev.disabled,
.owl-next.disabled {
  visibility: hidden;
}
.owl-nav {
  position: absolute;
  width: 100%;
  top: 40%;
  pointer-events: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 10px;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  object-fit: cover;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
/* No Js */
.no-js .owl-carousel {
  display: block;
}
/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}
/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 3px;
  border: solid 1px gainsboro;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 18px;
  font-weight: normal;
  height: 50px;
  line-height: 48px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
}
.nice-select:hover {
  border-color: #cfcfcf;
}
.nice-select:active,
.nice-select:focus {
  border: 1px solid gainsboro;
}
.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 12px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}
.nice-select.open {
  border-bottom: none;
}
.nice-select.open:after {
  transform: rotate(-135deg);
}
.nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}
.nice-select.disabled {
  border-color: #e1e1e1;
  color: #999;
  pointer-events: none;
}
.nice-select.disabled:after {
  border-color: #cccccc;
}
.nice-select.wide {
  width: 100%;
}
.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}
.nice-select.right {
  float: right;
}
.nice-select.right .list {
  left: auto;
  right: 0;
}
.nice-select.small {
  font-size: 12px;
  height: 36px;
  line-height: 34px;
}
.nice-select.small:after {
  height: 4px;
  width: 4px;
}
.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}
.nice-select .list {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid gainsboro;
  border-top: none;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 90%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(- 25px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
}
.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
  color: #363636;
}
.nice-select .list:before {
  content: " ";
  width: 95%;
  height: 1px;
  background-color: gainsboro;
  z-index: 9;
  top: 0;
  position: relative;
  display: block;
  margin: 0px auto;
}
.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  line-height: 48px;
  list-style: none;
  min-height: 48px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  transition: all 0.2s;
  width: 100%;
}
.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #c80046;
  color: #ffffff;
}
.nice-select .option.selected {
  font-weight: bold;
}
.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}
.no-csspointerevents .nice-select .list {
  display: none;
}
.no-csspointerevents .nice-select.open .list {
  display: block;
}
/* Grid Page Header
********************************************************************/
/* Grid Section Header
********************************************************************/
/* Grid Social Buttons
********************************************************************/
/* Grid Postal Address
********************************************************************/
/* Grid Page List
********************************************************************/
.nc-grid-pagelist a {
  text-decoration: underline;
  font-size: 16px;
}
.nc-grid-pagelist a:hover {
  text-decoration: none;
}
/* Grid News List
********************************************************************/
.nc-grid-newslist,
.nc-grid-slideshowinline {
  margin: 0px auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  max-width: 90%;
}
.nc-grid-newslist .owl-next {
  float: right;
  pointer-events: all;
  position: relative;
  right: -70px;
}
@media (max-width: 768px) {
  .nc-grid-newslist .owl-next {
    right: 0px;
  }
}
.nc-grid-newslist .owl-prev {
  float: left;
  pointer-events: all;
  position: relative;
  left: -70px;
}
@media (max-width: 768px) {
  .nc-grid-newslist .owl-prev {
    left: 0px;
  }
}
.nc-grid-newslist .owl-nav .owl-next svg,
.nc-grid-newslist .owl-nav .owl-prev svg {
  fill: #a5a5a5;
}
.nc-grid-newslist .owl-nav.disabled {
  display: none;
}
.nc-grid-newslist .owl-dots .owl-dot {
  display: inline-block;
  width: 7px;
  border-radius: 7px;
  height: 7px;
  background-color: #acacac;
  margin: 10px 10px;
}
.nc-grid-newslist .owl-dots .owl-dot.active {
  background-color: #c80046;
}
.nc-grid-newslist .owl-dots.disabled {
  display: none;
}
.nc-grid-newslist .owl-stage-outer {
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
}
.nc-grid-newslist__publishedDate,
.nc-grid-slideshowinline__publishedDate {
  color: #a5a5a5;
  font-size: 14px;
  text-transform: uppercase;
}
.nc-grid-slideshow-image,
.carousel-image {
  background-color: #ffffff;
  min-height: 315px;
  -webkit-box-shadow: -2px 9px 19px 0px #c2c2c2;
  -moz-box-shadow: -2px 9px 19px 0px #c2c2c2;
  box-shadow: -2px 3px 19px 0px #c2c2c2;
  border-radius: 10px;
}
.nc-grid-slideshow-image a,
.carousel-image a {
  text-decoration: none;
  color: black;
}
.nc-grid-slideshow-image figure,
.carousel-image figure {
  height: 100%;
}
.nc-grid-slideshow-image figure img,
.carousel-image figure img {
  height: 150px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.nc-grid-slideshow-image figcaption,
.carousel-image figcaption {
  padding: 20px;
  position: relative;
}
.nc-grid-slideshow-image figcaption h1,
.carousel-image figcaption h1,
.nc-grid-slideshow-image figcaption h2,
.carousel-image figcaption h2 {
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 18px;
  font-weight: normal;
}
/* Grid Slideshow
********************************************************************/
/* Grid Slideshow Inline
********************************************************************/
.nc-grid-slideshowinline .owl-next {
  float: right;
  pointer-events: all;
  position: relative;
  right: -70px;
}
@media (max-width: 768px) {
  .nc-grid-slideshowinline .owl-next {
    right: 0px;
  }
}
.nc-grid-slideshowinline .owl-prev {
  float: left;
  pointer-events: all;
  position: relative;
  left: -70px;
}
@media (max-width: 768px) {
  .nc-grid-slideshowinline .owl-prev {
    left: 0px;
  }
}
.nc-grid-slideshowinline .owl-nav .owl-next svg,
.nc-grid-slideshowinline .owl-nav .owl-prev svg {
  fill: #a5a5a5;
}
.nc-grid-slideshowinline .owl-nav.disabled {
  display: none;
}
.picture__background-image {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 4px solid white;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
/* Grid Embed Media
********************************************************************/
.nc-grid-embedmedia .embed-preview-image {
  cursor: pointer;
}
.nc-grid-embedmedia .embed-media-item {
  display: none;
}
/* Cases component
********************************************************************/
.case,
.sm-caption-case,
.md-caption-case,
.lg-caption-case {
  color: grey;
}
.case figure,
.sm-caption-case figure,
.md-caption-case figure,
.lg-caption-case figure {
  position: relative;
  overflow: hidden;
}
.case figure:hover,
.sm-caption-case figure:hover,
.md-caption-case figure:hover,
.lg-caption-case figure:hover {
  cursor: pointer;
}
.case figure:hover > figcaption,
.sm-caption-case figure:hover > figcaption,
.md-caption-case figure:hover > figcaption,
.lg-caption-case figure:hover > figcaption {
  padding-bottom: 25px;
}
.case figure:hover > figcaption .more-info,
.sm-caption-case figure:hover > figcaption .more-info,
.md-caption-case figure:hover > figcaption .more-info,
.lg-caption-case figure:hover > figcaption .more-info {
  opacity: 1;
  max-height: 500px;
  -webkit-transition: max-height 0.5s ease-in;
  -moz-transition: max-height 0.5s ease-in;
  -o-transition: max-height 0.5s ease-in;
  transition: max-height 0.5s ease-in;
}
.case figure .figure__bg,
.sm-caption-case figure .figure__bg,
.md-caption-case figure .figure__bg,
.lg-caption-case figure .figure__bg {
  background: black;
  background: -webkit-linear-gradient(transparent, black);
  background: -o-linear-gradient(transparent, black);
  background: -moz-linear-gradient(transparent, black);
  background: linear-gradient(transparent, black);
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  opacity: 0.7;
}
.case figure .icon-diagonal-arrow,
.sm-caption-case figure .icon-diagonal-arrow,
.md-caption-case figure .icon-diagonal-arrow,
.lg-caption-case figure .icon-diagonal-arrow {
  display: none;
  position: absolute;
  bottom: 30px;
  right: 20px;
  fill: white;
  z-index: 2;
}
.case img,
.sm-caption-case img,
.md-caption-case img,
.lg-caption-case img {
  width: 100% !important;
  margin: 0;
  display: initial;
  position: relative;
}
.case figcaption,
.sm-caption-case figcaption,
.md-caption-case figcaption,
.lg-caption-case figcaption {
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 2;
  color: white;
  width: 100%;
  padding: 0 20px;
  padding-bottom: 15px;
  -webkit-transition: padding 0.2s ease-in;
  -moz-transition: padding 0.2s ease-in;
  -o-transition: padding 0.2s ease-in;
  transition: padding 0.2s ease-in;
}
@media (max-width: 768px) {
  .case figcaption,
  .sm-caption-case figcaption,
  .md-caption-case figcaption,
  .lg-caption-case figcaption {
    display: block;
    text-shadow: 1px 2px 0 #333;
  }
}
.case figcaption h1,
.sm-caption-case figcaption h1,
.md-caption-case figcaption h1,
.lg-caption-case figcaption h1,
.case figcaption h2,
.sm-caption-case figcaption h2,
.md-caption-case figcaption h2,
.lg-caption-case figcaption h2 {
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 18px;
  margin-bottom: 5px;
  margin-top: 0;
  font-weight: 600;
}
.case figcaption .more-info,
.sm-caption-case figcaption .more-info,
.md-caption-case figcaption .more-info,
.lg-caption-case figcaption .more-info {
  font-size: 14px;
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 100;
  opacity: 0;
  max-height: 0;
  -webkit-transition: max-height 0.2s ease-out;
  -moz-transition: max-height 0.2s ease-out;
  -o-transition: max-height 0.2s ease-out;
  transition: max-height 0.2s ease-out;
  bottom: 5px;
}
.case figcaption .more-info svg,
.sm-caption-case figcaption .more-info svg,
.md-caption-case figcaption .more-info svg,
.lg-caption-case figcaption .more-info svg {
  position: relative;
  top: 3px;
  margin-left: 5px;
}
#cases {
  margin: 3rem 0;
}
#cases:after {
  content: '';
  display: block;
  clear: both;
}
#cases .case {
  margin: 0;
  margin-bottom: 30px;
  float: left;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 300px;
}
#cases .case figure,
#cases .case img,
#cases .case .figure__bg {
  height: 300px;
  min-height: 300px !important;
}
.sm-caption-case {
  height: 200px;
}
.sm-caption-case figure,
.sm-caption-case img,
.sm-caption-case .figure__bg {
  height: 200px;
  min-height: 200px !important;
}
.md-caption-case {
  height: 250px;
}
.md-caption-case figure,
.md-caption-case img,
.md-caption-case .figure__bg {
  height: 250px;
}
.lg-caption-case {
  height: 300px;
}
.lg-caption-case figure,
.lg-caption-case img,
.lg-caption-case .figure__bg {
  height: 300px;
}
.case__subtitle {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 300;
  font-size: 16px;
  margin-top: 0;
  padding-right: 10px;
}
.case__description {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 100;
  font-size: 14px;
  margin-top: 0;
  padding-right: 10px;
}
/* Animated Numbers
********************************************************************/
.number {
  margin-bottom: 3rem;
  text-align: center;
}
.animated__number {
  font-family: 'Averta', "Arial", "sans-serif";
  visibility: hidden;
  opacity: 0 !important;
  color: #c80046;
  text-align: center;
  font-weight: bolder;
  font-size: 55px;
  background: -webkit-linear-gradient(#c80046, #fda981);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: opacity 0.5s, visibility 0.5s;
}
.animated__number.in-view {
  display: inline-block;
  visibility: visible;
  opacity: 1 !important;
  transition: opacity 0.5s, visibility 0.5s;
}
.description__number {
  text-align: center;
}
/* Icon Info
********************************************************************/
.info-icon-block {
  text-align: center;
  margin-top: 2rem;
}
.info-icon-block svg {
  display: block;
  margin: 0px auto;
}
/* Testimonial
********************************************************************/
.testimonial {
  margin: 3rem 0;
  float: left;
  width: 100%;
}
.testimonial__picture {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 200px;
  height: 200px;
  border-radius: 100px;
  margin-left: 20px;
  position: relative;
}
.testimonial__picture-container {
  position: relative;
}
.testimonial__picture-container .icon {
  position: absolute;
  right: 40px;
  top: 0;
  width: 40px;
  height: 40px;
}
@media (max-width: 768px) {
  .testimonial__picture-container .icon {
    position: relative;
    right: auto;
    top: auto;
    width: 20px;
    height: 20px;
  }
}
.testimonial .description p {
  margin-top: 0;
}
/* Employee
********************************************************************/
.nc-grid-employee .employee {
  text-align: center;
  padding: 3rem 0;
}
.nc-grid-employee .employee__picture {
  border-radius: 88px;
  width: 175px;
  height: 175px;
}
.nc-grid-employee .employee__name {
  font-size: 22px;
}
.nc-grid-employee .employee__position {
  font-size: 15px;
  font-weight: 900;
}
.nc-grid-employee .employee__description {
  max-width: 100%;
  width: 100%;
}
.nc-grid-employee .employee__telephone {
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 32px;
}
/* last events list
********************************************************************/
.nc-grid-eventlist .event {
  text-align: center;
  padding: 0;
  border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: -2px 9px 19px 0px #c2c2c2;
  -moz-box-shadow: -2px 9px 19px 0px #c2c2c2;
  box-shadow: -2px 3px 19px 0px #c2c2c2;
}
.nc-grid-eventlist .event__image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.nc-grid-eventlist .event__image__container {
  padding: 0;
}
.nc-grid-eventlist .event__nextSeminar {
  font-size: 16px;
  text-transform: uppercase;
  color: #acacac;
}
.nc-grid-eventlist .event .button {
  margin: 10px 0;
}
/* Contact Card
********************************************************************/
.contact-card {
  -webkit-box-shadow: -2px 9px 19px 0px #c2c2c2;
  -moz-box-shadow: -2px 9px 19px 0px #c2c2c2;
  box-shadow: -2px 3px 19px 0px #c2c2c2;
  border-radius: 10px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
  overflow: hidden;
}
.contact-card header img {
  width: 100%;
  height: 300px;
}
.contact-card header h2 {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 600;
  font-size: 30px;
  margin: 30px 0;
}
.contact-card a {
  text-decoration: none;
  color: inherit;
}
.contact-card__content {
  padding: 0 80px;
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 300;
}
.contact-card__content address {
  font-style: normal;
}
.contact-card__content .separator {
  width: 80px;
  display: block;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 2px;
  background: #c80046;
}
.contact-card__content .contact-info {
  margin-top: 20px;
  margin-bottom: 40px;
}
.contact-card__content .contact-info p {
  margin: 0;
}
/* Image Slider
********************************************************************/
.nc-grid-imageSlider {
  position: relative;
}
.nc-grid-imageSlider .owl-nav .owl-next svg,
.nc-grid-imageSlider .owl-nav .owl-prev svg {
  fill: #a5a5a5;
}
.nc-grid-imageSlider .owl-nav.disabled {
  display: none;
}
.nc-grid-imageSlider .owl-dots {
  position: absolute;
  margin: auto;
  bottom: 20px;
  left: 0;
  right: 0;
}
.nc-grid-imageSlider .owl-dots .owl-dot {
  display: inline-block;
  width: 10px;
  border-radius: 10px;
  height: 14px;
  background-color: #ffffff;
  margin: 10px 10px;
  border: 1px solid #ffffff;
}
.nc-grid-imageSlider .owl-dots .owl-dot.active {
  background-color: #c80046;
}
.nc-grid-imageSlider .owl-dots.disabled {
  display: none;
}
/* Fact Box
********************************************************************/
.fact-box {
  position: relative;
  width: 100%;
}
.fact-box__content {
  background-color: #c80046;
  color: #ffffff;
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 20px;
  font-weight: 300;
  font-style: italic;
  padding: 35px;
  border-radius: 10px;
}
.fact-box__user {
  position: relative;
  left: 15%;
  top: -15px;
  float: left;
}
.fact-box__user__picture {
  float: left;
}
.fact-box__user__picture picture {
  float: left;
}
.fact-box__user__picture picture img {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  border-radius: 40px;
  border: 4px solid white;
  display: inline-block;
}
.fact-box__user__info {
  float: left;
  height: 80px;
  position: relative;
  top: 0;
  padding: 15px 10px;
}
.fact-box__user__info p {
  margin: 0;
}
/* Highlight Card
********************************************************************/
.highlight-card {
  margin-bottom: 30px;
}
.highlight-card h3.card-title {
  font-weight: 900;
}
@media (min-width: 768px) {
  .highlight-card h3.card-title {
    display: inline-block;
  }
}
.highlight-card .grid-highlight-card {
  border: 1px solid gainsboro;
  padding: 40px;
}
@media (min-width: 768px) {
  .highlight-card .grid-highlight-card {
    min-height: 400px;
    max-height: 400px;
  }
}
.highlight-card .grid-highlight-card svg {
  display: block;
  width: 50px;
  height: 50px;
}
@media (min-width: 768px) {
  .highlight-card .grid-highlight-card svg {
    display: inline-block;
    position: relative;
    top: 20px;
    margin-right: 30px;
  }
}
.highlight-card .grid-highlight-card .description {
  color: #6d6c6c;
  font-family: 'Averta', "Arial", "sans-serif";
  font-size: 17px;
}
@media (min-width: 768px) {
  .highlight-card .grid-highlight-card .description {
    padding-left: 90px;
    padding-right: 50px;
  }
}
.highlight-card .grid-highlight-card .description p {
  margin: 0;
  line-height: 25px;
}
.highlight-card .grid-highlight-card .description a {
  color: #c80046;
  text-decoration: none;
}
.highlight-card .grid-highlight-card .description a:hover {
  color: #490019;
}
.highlight-card .grid-highlight-card .description ul {
  list-style: none;
  padding: 0;
  margin-bottom: 5px;
  margin-top: 5px;
}
.highlight-card .grid-highlight-card .description ul li {
  padding-left: 1.3em;
}
.highlight-card .grid-highlight-card .description ul li:before {
  content: ">\00a0";
  display: inline-block;
  margin-left: -3.1em;
  width: 1.3em;
  color: #c80046;
  font-size: 12px;
  line-height: 25px;
  position: relative;
  top: -2px;
  font-family: cursive;
}
.highlight-card .grid-highlight-card__link {
  float: left;
  width: 100%;
}
.highlight-card .grid-highlight-card__link:hover {
  cursor: pointer;
  -webkit-box-shadow: 0 0 35px #ddd;
  box-shadow: 0 0 35px #ddd;
}
.highlight-card div[class*="col--"] {
  padding: 0;
}
/* Standard padding on column rows
********************************************************************/
div[class^="-column-"],
div[class*="-column-"],
div[class^="-columns-"],
div[class*="-columns-"] {
  padding: 50px 15px;
}
@media (min-width: 992px) {
  div[class^="-column-"],
  div[class*="-column-"],
  div[class^="-columns-"],
  div[class*="-columns-"] {
    padding: 100px 15px;
  }
}
/* Background rows styles
********************************************************************/
div[data-background-image] {
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
  position: relative;
  opacity: .999;
  overflow: hidden;
}
/* Grid Themes
********************************************************************/
.theme,
.theme1,
.theme2,
.theme3,
.theme4,
.theme5,
.theme-lightgrey,
.theme-black {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.theme1 {
  color: #363636;
  background-color: #C0E3DE;
}
.theme1 h1,
.theme1 h2,
.theme1 h3,
.theme1 p {
  color: #363636;
}
.theme1 svg {
  fill: #363636;
}
.theme2 {
  background-color: #75BC7F;
  color: #363636;
}
.theme2 h1,
.theme2 h2,
.theme2 h3,
.theme2 p {
  color: #363636;
}
.theme2 svg {
  fill: #ffffff;
}
.theme3 {
  color: #ffffff;
  background-color: #C80046;
}
.theme3 h1,
.theme3 h2,
.theme3 h3,
.theme3 p {
  color: #ffffff;
}
.theme3 svg {
  fill: #ffffff;
}
.theme3 .nc-grid-richtexteditor a {
  text-decoration: underline;
  color: #ffffff;
}
.theme3 .nc-grid-richtexteditor a:hover {
  text-decoration: none;
  color: #acacac;
}
.theme4 {
  background-color: #7C0435;
  color: #ffffff;
  padding: 7rem 0;
}
.theme5 {
  background-color: #510035;
  color: #ffffff;
}
.theme5 h1,
.theme5 h2,
.theme5 h3,
.theme5 p {
  color: #ffffff;
}
.theme-lightgrey {
  background-color: #f5f5f5;
}
.theme-black {
  background-color: #222222;
  color: white;
}
.theme-black svg {
  fill: #ffffff;
}
/* Grid Themes
********************************************************************/
.thiner-font {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 100;
}
.light-font {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 300;
}
.smaller-hero-banner {
  font-size: 45px;
  font-weight: 700;
  line-height: 60.5px;
}
.black-font {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: 900;
}
.remove-link-styles {
  text-decoration: none !important;
  color: #363636;
}
.remove-link-styles:hover {
  text-decoration: none !important;
  color: #363636;
}
.remove-link-styles a {
  text-decoration: none !important;
  color: #363636;
}
.remove-link-styles a:hover {
  text-decoration: none !important;
  color: #363636;
}
.nc-grid-richtexteditor {
  padding: 0 15px 0 15px;
}
.nc-grid-richtexteditor a {
  text-decoration: none;
  color: #C80046;
}
.nc-grid-richtexteditor a:hover {
  text-decoration: none;
  color: #acacac;
}
.nc-grid-richtexteditor .button a {
  color: #ffffff;
}
.nc-grid-richtexteditor .button--light a,
.nc-grid-richtexteditor .button--ghost a {
  color: #C80046;
}
.nc-grid-richtexteditor span:not(.button) a:not(.button) {
  text-decoration: underline;
}
.nc-grid-richtexteditor span:not(.button) a:not(.button):hover {
  text-decoration: none;
  color: #acacac;
}
/*Video
*********************************************************************/
.case-grid-video-background-container {
  position: relative;
}
#case-grid-video {
  min-height: 625px;
  margin-top: -625px;
  background-color: #FFF;
}
@media (max-width: 992px) {
  #case-grid-video {
    min-height: 400px;
    margin-top: -400px;
  }
}
#case-grid-video .video-container {
  position: relative;
  width: 100%;
}
#case-grid-video .overlayBox {
  height: 625px;
  position: absolute;
  z-index: 3;
  display: none;
  width: 100%;
  margin: 0 auto;
  top: 0;
  background-color: #000;
  overflow: hidden;
}
@media (max-width: 992px) {
  #case-grid-video .overlayBox {
    height: 400px;
  }
}
#case-grid-video .overlayContent {
  width: 100%;
  height: 100%;
  text-align: right;
  max-width: 1111px;
  margin: 0 auto;
}
#case-grid-video .closeLink {
  position: relative;
  top: 50px;
  right: 12px;
  z-index: 1;
  font-size: 24px;
  color: #FFF;
  padding: 15px;
}
#case-grid-video .youtube-container {
  margin-top: -34px;
  margin-bottom: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  height: 0;
}
#case-grid-video .video-tag {
  padding-top: 175px;
  color: #FFF;
  font-size: 28px;
  text-align: center;
  z-index: 2;
  position: relative;
}
@media (max-width: 992px) {
  #case-grid-video .video-tag {
    padding-top: 75px;
  }
}
@media (max-width: 768px) {
  #case-grid-video .video-tag {
    padding-top: 50px;
  }
}
#case-grid-video .video-description {
  color: #FBF9FA;
  font-size: 18px;
  text-align: center;
  margin-bottom: 25px;
  z-index: 2;
  position: relative;
  padding: 0px 25px;
}
#case-grid-video .video-play-button {
  display: block;
  margin: 0 auto;
  opacity: 1;
  z-index: 2;
  position: relative;
  color: white;
}
.case-grid-video-background-container .case-grid-video-background {
  height: 625px;
  background-position-x: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}
@media (max-width: 992px) {
  .case-grid-video-background-container .case-grid-video-background {
    height: 400px;
  }
}
.case-grid-video-background-container .case-grid-video-background-brightness {
  height: 625px;
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 0;
}
@media (max-width: 992px) {
  .case-grid-video-background-container .case-grid-video-background-brightness {
    height: 400px;
  }
}
.case-grid-video-background-container .youtube-container iframe {
  padding: 0;
}
.youtube-container iframe {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
/* Grid fixes
********************************************************************/
.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}
.container-fluid .row div[class*="col--"] {
  padding: 0;
}
@media (max-width: 768px) {
  .padding-on-mobile {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
/* Social buttons
********************************************************************/
.social-buttons {
  padding: 10px 0;
  text-align: center;
}
.social-buttons ul li.upHover {
  transition: transform 1s ease-out;
}
.social-buttons ul li.upHover:hover {
  transform: translate(0, -10px);
}
.social-buttons ul li {
  display: inline-block;
  transition: transform 1s ease-out;
}
.social-buttons ul li a {
  padding: 5px;
  transition: opacity ease 0.5s;
}
.social-buttons ul li a img {
  width: 30px;
  height: auto;
}
.social-buttons ul li a:hover {
  opacity: 0.7;
}
/* Novicell Fashion Device
********************************************************************/
.fashion-media-device-block {
  position: relative;
}
.fashion-media-device-block .icon {
  width: 40px;
  height: 40px;
}
.fashion-media-device-block div[class*="col--"] {
  position: initial;
}
.nc-grid-fashionDevice {
  position: initial;
}
@media (max-width: 480px) {
  .nc-grid-fashionDevice video.video-background {
    display: block;
  }
}
.nc-grid-fashionDevice__title {
  font-weight: bold;
}
.nc-grid-fashionDevice__Iphone {
  width: 285px;
  height: 593px;
  position: relative;
  display: block;
  margin: auto;
  pointer-events: none;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice__Iphone {
    margin-top: 30px;
  }
}
.nc-grid-fashionDevice__Iphone:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  background: url(/dist/images/iphone-front.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.nc-grid-fashionDevice__Iphone .nc-grid-fashionDevice__image,
.nc-grid-fashionDevice__Iphone img,
.nc-grid-fashionDevice__Iphone .video-background__container {
  width: calc(100% - 25px);
  height: calc(100% - 140px);
  object-fit: fill;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: black;
}
.nc-grid-fashionDevice__Iphone .nc-grid-fashionDevice__image .video-background,
.nc-grid-fashionDevice__Iphone img .video-background,
.nc-grid-fashionDevice__Iphone .video-background__container .video-background {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: all;
}
.nc-grid-fashionDevice__Iphone .video-controls {
  position: absolute;
  display: block;
  margin: auto;
  width: 220px;
  height: 40px;
  max-width: 90%;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  z-index: 5;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.nc-grid-fashionDevice__Iphone .video-controls button {
  background: transparent;
  -webkit-appearance: none;
  border: none;
  pointer-events: all;
  z-index: 2;
  position: relative;
}
.nc-grid-fashionDevice__Iphone .video-controls button:active,
.nc-grid-fashionDevice__Iphone .video-controls button:focus {
  outline: 0;
}
.nc-grid-fashionDevice__Iphone .video-controls button .icon {
  fill: rgba(200, 0, 70, 0.8);
}
.nc-grid-fashionDevice__Iphone .video-controls button:hover {
  cursor: pointer;
}
.nc-grid-fashionDevice__Iphone .video-controls button .unmute-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Iphone .video-controls button .mute-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
  position: relative;
  left: -5px;
}
.nc-grid-fashionDevice__Iphone .video-controls button .play-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Iphone .video-controls button .pause-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Iphone .video-controls button.play-pause {
  pointer-events: none;
}
.nc-grid-fashionDevice__Iphone:hover .video-controls {
  opacity: 1;
  transition: opacity 0.5s;
}
.nc-grid-fashionDevice.halfiphone {
  padding-top: 150px;
  padding-bottom: 150px;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice.halfiphone {
    padding-top: 50px;
    padding-bottom: 300px;
  }
}
.nc-grid-fashionDevice__HalfIphone {
  width: 350px;
  height: 610px;
  position: absolute;
  display: block;
  margin: auto;
  bottom: 0;
  right: 20%;
  overflow: hidden;
  pointer-events: none;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice__HalfIphone {
    width: 200px;
    height: 340px;
    left: 0;
    right: 0;
  }
}
.nc-grid-fashionDevice__HalfIphone:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  background: url(/dist/images/Half_iphone-front.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.nc-grid-fashionDevice__HalfIphone .nc-grid-fashionDevice__image,
.nc-grid-fashionDevice__HalfIphone img,
.nc-grid-fashionDevice__HalfIphone .video-background__container {
  width: calc(100% - 25px);
  height: calc(100% - 90px);
  object-fit: fill;
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 90px;
  bottom: 0;
  margin: auto;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: black;
}
.nc-grid-fashionDevice__HalfIphone .nc-grid-fashionDevice__image .video-background,
.nc-grid-fashionDevice__HalfIphone img .video-background,
.nc-grid-fashionDevice__HalfIphone .video-background__container .video-background {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  pointer-events: all;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice__HalfIphone .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__HalfIphone img,
  .nc-grid-fashionDevice__HalfIphone .video-background__container {
    width: calc(100% - 15px);
    height: calc(100% - 50px);
    top: 50px;
  }
}
.nc-grid-fashionDevice__HalfIphone .video-controls {
  position: absolute;
  display: block;
  margin: auto;
  width: 220px;
  height: 40px;
  max-width: 90%;
  left: 0;
  right: 0;
  top: 90px;
  bottom: 0;
  z-index: 5;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button {
  background: transparent;
  -webkit-appearance: none;
  border: none;
  pointer-events: all;
  z-index: 2;
  position: relative;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button:active,
.nc-grid-fashionDevice__HalfIphone .video-controls button:focus {
  outline: 0;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button .icon {
  fill: rgba(200, 0, 70, 0.8);
}
.nc-grid-fashionDevice__HalfIphone .video-controls button:hover {
  cursor: pointer;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button .unmute-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button .mute-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
  position: relative;
  left: -5px;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button .play-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button .pause-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfIphone .video-controls button.play-pause {
  pointer-events: none;
}
.nc-grid-fashionDevice__HalfIphone:hover .video-controls {
  opacity: 1;
  transition: opacity 0.5s;
}
.nc-grid-fashionDevice__Macbook {
  width: 1039px;
  height: 560px;
  position: relative;
  display: block;
  margin: auto;
  margin-top: 30px;
  max-width: 100%;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .nc-grid-fashionDevice__Macbook {
    width: 610px;
    height: 320px;
  }
}
@media (max-width: 768px) {
  .nc-grid-fashionDevice__Macbook {
    width: 425px;
    height: 220px;
  }
}
@media (max-width: 480px) {
  .nc-grid-fashionDevice__Macbook {
    width: 300px;
    height: 220px;
  }
}
.nc-grid-fashionDevice__Macbook:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  background: url(/dist/images/macbook-white.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (max-width: 480px) {
  .nc-grid-fashionDevice__Macbook:after {
    height: calc(100% - 60px);
  }
}
.nc-grid-fashionDevice__Macbook .nc-grid-fashionDevice__image,
.nc-grid-fashionDevice__Macbook img,
.nc-grid-fashionDevice__Macbook .video-background__container {
  width: calc(100% - 315px);
  height: calc(100% - 105px);
  position: relative;
  display: block;
  z-index: 1;
  top: 37px;
  margin: 0 auto;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: black;
}
@media (max-width: 1200px) {
  .nc-grid-fashionDevice__Macbook .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__Macbook img,
  .nc-grid-fashionDevice__Macbook .video-background__container {
    width: calc(100% - 180px);
    height: calc(100% - 52px);
    top: 16px;
  }
}
@media (max-width: 768px) {
  .nc-grid-fashionDevice__Macbook .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__Macbook img,
  .nc-grid-fashionDevice__Macbook .video-background__container {
    width: calc(100% - 105px);
    height: calc(100% - 34px);
    top: 10px;
  }
}
@media (max-width: 480px) {
  .nc-grid-fashionDevice__Macbook .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__Macbook img,
  .nc-grid-fashionDevice__Macbook .video-background__container {
    width: calc(100% - 90px);
    height: calc(100% - 90px);
    top: 40px;
  }
}
.nc-grid-fashionDevice__Macbook .nc-grid-fashionDevice__image .video-background,
.nc-grid-fashionDevice__Macbook img .video-background,
.nc-grid-fashionDevice__Macbook .video-background__container .video-background {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: all;
}
.nc-grid-fashionDevice__Macbook .video-controls {
  position: absolute;
  display: block;
  margin: auto;
  width: 220px;
  height: 40px;
  max-width: 90%;
  left: 0;
  right: 0;
  top: -30px;
  bottom: 0;
  z-index: 5;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
.nc-grid-fashionDevice__Macbook .video-controls button {
  background: transparent;
  -webkit-appearance: none;
  border: none;
  pointer-events: all;
  z-index: 2;
  position: relative;
}
.nc-grid-fashionDevice__Macbook .video-controls button:active,
.nc-grid-fashionDevice__Macbook .video-controls button:focus {
  outline: 0;
}
.nc-grid-fashionDevice__Macbook .video-controls button .icon {
  fill: rgba(200, 0, 70, 0.8);
}
.nc-grid-fashionDevice__Macbook .video-controls button:hover {
  cursor: pointer;
}
.nc-grid-fashionDevice__Macbook .video-controls button .unmute-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Macbook .video-controls button .mute-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
  position: relative;
  left: -5px;
}
.nc-grid-fashionDevice__Macbook .video-controls button .play-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Macbook .video-controls button .pause-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__Macbook .video-controls button.play-pause {
  pointer-events: none;
}
.nc-grid-fashionDevice__Macbook:hover .video-controls {
  opacity: 1;
  transition: opacity 0.5s;
}
.nc-grid-fashionDevice.halfmacbook {
  padding-top: 160px;
  padding-bottom: 160px;
  position: relative;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice.halfmacbook {
    padding-top: 50px;
    padding-bottom: 425px;
  }
}
.nc-grid-fashionDevice__HalfMacbook {
  width: 424px;
  height: 463px;
  position: absolute;
  right: 0;
  top: calc(50% - 463px);
  bottom: 0;
  display: block;
  margin: auto;
  pointer-events: none;
}
@media (max-width: 1200px) {
  .nc-grid-fashionDevice__HalfMacbook {
    width: 524px;
    height: 563px;
  }
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice__HalfMacbook {
    width: 384px;
    height: 413px;
    bottom: 0px;
    top: auto;
  }
}
@media (max-width: 768px) {
  .nc-grid-fashionDevice__HalfMacbook {
    width: 324px;
    height: 363px;
    bottom: 0px;
    top: auto;
  }
}
.nc-grid-fashionDevice__HalfMacbook:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  background: url(/dist/images/Half_macbook-white.png);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.nc-grid-fashionDevice__HalfMacbook .nc-grid-fashionDevice__image,
.nc-grid-fashionDevice__HalfMacbook img,
.nc-grid-fashionDevice__HalfMacbook .video-background__container {
  width: calc(100% - 130px);
  height: calc(100% - 90px);
  object-fit: fill;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 34px;
  margin: auto;
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-color: black;
}
@media (max-width: 992px) {
  .nc-grid-fashionDevice__HalfMacbook .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__HalfMacbook img,
  .nc-grid-fashionDevice__HalfMacbook .video-background__container {
    width: calc(100% - 105px);
    height: calc(100% - 82px);
    top: 30px;
  }
}
@media (max-width: 768px) {
  .nc-grid-fashionDevice__HalfMacbook .nc-grid-fashionDevice__image,
  .nc-grid-fashionDevice__HalfMacbook img,
  .nc-grid-fashionDevice__HalfMacbook .video-background__container {
    width: calc(100% - 98px);
    height: calc(100% - 70px);
    top: 25px;
  }
}
.nc-grid-fashionDevice__HalfMacbook .nc-grid-fashionDevice__image .video-background,
.nc-grid-fashionDevice__HalfMacbook img .video-background,
.nc-grid-fashionDevice__HalfMacbook .video-background__container .video-background {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: all;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls {
  position: absolute;
  display: block;
  margin: auto;
  width: 220px;
  height: 40px;
  max-width: 90%;
  left: 150px;
  right: 0;
  top: -30px;
  bottom: 0;
  z-index: 5;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
}
@media (max-width: 768px) {
  .nc-grid-fashionDevice__HalfMacbook .video-controls {
    left: 120px;
  }
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button {
  background: transparent;
  -webkit-appearance: none;
  border: none;
  pointer-events: all;
  z-index: 2;
  position: relative;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button:active,
.nc-grid-fashionDevice__HalfMacbook .video-controls button:focus {
  outline: 0;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button .icon {
  fill: rgba(200, 0, 70, 0.8);
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button:hover {
  cursor: pointer;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button .unmute-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button .mute-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
  position: relative;
  left: -5px;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button .play-icon {
  opacity: 0;
  width: 0;
  height: 0;
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button .pause-icon {
  transition: opacity 0.3s;
}
.nc-grid-fashionDevice__HalfMacbook .video-controls button.play-pause {
  pointer-events: none;
}
.nc-grid-fashionDevice__HalfMacbook:hover .video-controls,
.nc-grid-fashionDevice__HalfMacbook:focus .video-controls,
.nc-grid-fashionDevice__HalfMacbook:active .video-controls {
  opacity: 1;
  transition: opacity 0.5s;
}
/* CTA Banner
********************************************************************/
.ncgrid-cta-banner .button {
  margin-bottom: 30px;
}
.ncgrid-cta-banner__title {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
.ncgrid-cta-banner__title--small {
  text-align: left;
}
.ncgrid-cta-banner__content {
  padding: 15px;
  padding-left: 30px;
}
.ncgrid-cta-banner .ncgrid-cta-banner__imageContainer--twocolumns.col--xs-12.col--md-6 {
  padding: 15px;
}
/* Novicell Responsive Utilities Classes
********************************************************************/
@media (min-width: 1200px) {
  div[data-hide*="hidden-lg"] {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  div[data-hide*="hidden-md"] {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  div[data-hide*="hidden-sm"] {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  div[data-hide*="hidden-xs"] {
    display: none;
  }
}
/*Umbraco forms
*********************************************************************/
#contour input.text,
#contour textarea {
  max-width: 100% !important;
  width: 100%;
}
.contour input.text,
.contour textarea {
  max-width: 100% !important;
  width: 100%;
}
#countour.form-font-sm,
.contour.form-font-sm {
  font-size: 0.8em;
}
#countour.form-padding,
.contour.form-padding {
  padding: 25px;
}
#countour.form-border,
.contour.form-border {
  border: 1px solid #DDD;
  border-radius: 5px;
}
#countour .umbraco-forms.missing-library,
.contour .umbraco-forms.missing-library {
  display: none;
}
#countour .contourPage .contourFieldSet,
.contour .contourPage .contourFieldSet {
  position: relative;
}
#countour .contourPage .contourFieldSet input.text,
.contour .contourPage .contourFieldSet input.text,
#countour .contourPage .contourFieldSet input.title,
.contour .contourPage .contourFieldSet input.title,
#countour .contourPage .contourFieldSet textarea,
.contour .contourPage .contourFieldSet textarea,
#countour .contourPage .contourFieldSet select,
.contour .contourPage .contourFieldSet select {
  max-width: 100% !important;
  padding: 13px;
  display: block;
  width: 100%;
  border: 1px solid gainsboro;
  border-radius: 3px;
}
#countour .contourPage .contourFieldSet input.text:focus,
.contour .contourPage .contourFieldSet input.text:focus,
#countour .contourPage .contourFieldSet input.title:focus,
.contour .contourPage .contourFieldSet input.title:focus,
#countour .contourPage .contourFieldSet textarea:focus,
.contour .contourPage .contourFieldSet textarea:focus,
#countour .contourPage .contourFieldSet select:focus,
.contour .contourPage .contourFieldSet select:focus {
  outline: none;
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px gainsboro;
}
#countour .contourPage .contourFieldSet input.text.input-validation-error,
.contour .contourPage .contourFieldSet input.text.input-validation-error,
#countour .contourPage .contourFieldSet input.title.input-validation-error,
.contour .contourPage .contourFieldSet input.title.input-validation-error,
#countour .contourPage .contourFieldSet textarea.input-validation-error,
.contour .contourPage .contourFieldSet textarea.input-validation-error,
#countour .contourPage .contourFieldSet select.input-validation-error,
.contour .contourPage .contourFieldSet select.input-validation-error {
  border: 2px solid #c80046;
  background-color: transparent;
}
#countour .contourPage .contourFieldSet input.text.input-validation-error:after,
.contour .contourPage .contourFieldSet input.text.input-validation-error:after,
#countour .contourPage .contourFieldSet input.title.input-validation-error:after,
.contour .contourPage .contourFieldSet input.title.input-validation-error:after,
#countour .contourPage .contourFieldSet textarea.input-validation-error:after,
.contour .contourPage .contourFieldSet textarea.input-validation-error:after,
#countour .contourPage .contourFieldSet select.input-validation-error:after,
.contour .contourPage .contourFieldSet select.input-validation-error:after {
  content: "✖";
  color: #c80046;
  font-size: 18px;
  display: block;
  position: relative;
}
#countour .contourPage .contourFieldSet input[type=file],
.contour .contourPage .contourFieldSet input[type=file] {
  width: 100%;
}
#countour .contourPage .contourFieldSet select option:hover,
.contour .contourPage .contourFieldSet select option:hover {
  background-color: #c80046;
  color: #ffffff;
}
#countour .contourPage .contourFieldSet div,
.contour .contourPage .contourFieldSet div {
  position: relative;
  float: left;
  width: 100%;
}
#countour .contourPage .contourFieldSet div.error:after,
.contour .contourPage .contourFieldSet div.error:after {
  content: "✖";
  color: #c80046;
  font-size: 18px;
  display: block;
  position: absolute;
  right: 10px;
  top: 12px;
}
#countour .contourPage .contourFieldSet div.correct:after,
.contour .contourPage .contourFieldSet div.correct:after {
  content: "✔ ";
  color: #669933;
  font-size: 18px;
  display: block;
  position: absolute;
  right: 10px;
  top: 12px;
  font-weight: bold;
}
#countour .contourPage .contourFieldSet .field-validation-error span,
.contour .contourPage .contourFieldSet .field-validation-error span {
  color: #c80046;
  font-size: 16px;
}
#countour .contourPage .contourFieldSet textarea,
.contour .contourPage .contourFieldSet textarea {
  resize: none;
}
#countour .contourPage .contourFieldSet input[type=file],
.contour .contourPage .contourFieldSet input[type=file] {
  padding-bottom: 13px;
}
#countour .fieldLabel,
.contour .fieldLabel {
  padding: 0;
}
#countour .checkbox label,
.contour .checkbox label,
#countour .checkboxlist label,
.contour .checkboxlist label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: normal !important;
}
#countour .checkbox label:before,
.contour .checkbox label:before,
#countour .checkboxlist label:before,
.contour .checkboxlist label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-right: 10px;
  left: 0;
  bottom: 1px;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  border-radius: 3px;
  box-shadow: 0 0px 5px gainsboro inset;
}
#countour .checkbox label.checked:before,
.contour .checkbox label.checked:before,
#countour .checkboxlist label.checked:before,
.contour .checkboxlist label.checked:before {
  content: "\2713";
  text-shadow: 1px 1px 1px #c80046;
  font-size: 14px;
  color: #c80046;
  text-align: center;
  line-height: 19px;
  font-weight: bold;
}
#countour .checkbox input[type="checkbox"],
.contour .checkbox input[type="checkbox"],
#countour .checkboxlist input[type="checkbox"],
.contour .checkboxlist input[type="checkbox"] {
  display: none !important;
  -webkit-appearance: none;
}
#countour .checkbox.not-checked,
.contour .checkbox.not-checked,
#countour .checkboxlist.not-checked,
.contour .checkboxlist.not-checked,
#countour .checkbox.list-not-checked,
.contour .checkbox.list-not-checked,
#countour .checkboxlist.list-not-checked,
.contour .checkboxlist.list-not-checked {
  background-color: #ff6299;
  padding: 10px;
  width: 100%;
}
#countour .checkbox + span.field-validation-error,
.contour .checkbox + span.field-validation-error,
#countour .checkboxlist + span.field-validation-error,
.contour .checkboxlist + span.field-validation-error {
  color: #c80046 !important;
  font-size: 16px;
}
#countour .radiobuttonlist label,
.contour .radiobuttonlist label,
#countour .radiobutton label,
.contour .radiobutton label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 16px;
  color: #363636;
}
#countour .radiobuttonlist label:before,
.contour .radiobuttonlist label:before,
#countour .radiobutton label:before,
.contour .radiobutton label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-right: 10px;
  left: 0;
  bottom: 1px;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  border-radius: 10px;
  box-shadow: 0 0px 5px gainsboro inset;
}
#countour .radiobuttonlist input[type="radio"],
.contour .radiobuttonlist input[type="radio"],
#countour .radiobutton input[type="radio"],
.contour .radiobutton input[type="radio"] {
  display: none !important;
  -webkit-appearance: none;
}
#countour .radiobuttonlist input[type="radio"]:checked + label:before,
.contour .radiobuttonlist input[type="radio"]:checked + label:before,
#countour .radiobutton input[type="radio"]:checked + label:before,
.contour .radiobutton input[type="radio"]:checked + label:before {
  content: "●";
  text-shadow: 1px 1px 1px #c80046;
  font-size: 20px;
  color: #c80046;
  text-align: center;
  line-height: 15px;
}
#countour .radiobuttonlist.not-checked,
.contour .radiobuttonlist.not-checked,
#countour .radiobutton.not-checked,
.contour .radiobutton.not-checked,
#countour .radiobuttonlist.list-not-checked,
.contour .radiobuttonlist.list-not-checked,
#countour .radiobutton.list-not-checked,
.contour .radiobutton.list-not-checked {
  background-color: #ff6299;
  padding: 10px;
  width: 100%;
}
#countour .radiobuttonlist + span.field-validation-error,
.contour .radiobuttonlist + span.field-validation-error,
#countour .radiobutton + span.field-validation-error,
.contour .radiobutton + span.field-validation-error {
  color: #c80046 !important;
  font-size: 16px;
}
#countour .nice-select,
.contour .nice-select {
  margin-bottom: 20px;
}
#countour .umbraco-form-help-text,
.contour .umbraco-form-help-text {
  position: absolute !important;
  right: 0;
  top: 0;
  width: 100px;
  text-align: right;
  float: right;
}
#countour .umbraco-form-help-text .help-tip,
.contour .umbraco-form-help-text .help-tip {
  display: none;
  position: absolute !important;
  top: -60px;
  width: 200px;
  left: -35%;
  min-height: 50px;
  text-align: left;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  border-radius: 3px;
  box-shadow: 0 0 20px gainsboro;
  padding: 10px;
}
#countour .umbraco-form-help-text .help-tip:hover,
.contour .umbraco-form-help-text .help-tip:hover {
  display: block;
}
#countour .umbraco-form-help-text .help-tip:after,
.contour .umbraco-form-help-text .help-tip:after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  margin: auto;
}
#countour .umbraco-form-help-text .get-help,
.contour .umbraco-form-help-text .get-help {
  color: #c80046;
  text-decoration: underline;
  font-size: 16px;
}
#countour .umbraco-form-help-text .get-help:hover,
.contour .umbraco-form-help-text .get-help:hover {
  cursor: pointer;
}
#countour .contourNavigation,
.contour .contourNavigation {
  display: inline-block;
}
/* Hubspot forms styles  */
.nc-grid-hubspotform {
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .nc-grid-hubspotform {
    width: 100%;
  }
}
.nc-grid-hubspotform .actions {
  text-align: center;
}
.hbspt-form label {
  font-family: 'Averta', "Arial", "sans-serif";
  font-weight: bold;
}
.hbspt-form .input {
  line-height: normal;
  border-radius: 0px;
  padding-left: 1px;
  border: none;
  width: unset;
  height: unset;
  padding: unset;
  background: none;
}
.hbspt-form .hs-input {
  padding: 10px 10px 5px 5px;
  border: 1px solid gainsboro;
  border-radius: 3px;
  font-size: 18px;
}
.hbspt-form .hs-input:focus {
  box-shadow: 0 0 10px gainsboro;
}
.hbspt-form .hs-input:not([type=checkbox]):not([type=radio]) {
  width: 100%;
}
.hbspt-form textarea.hs-input {
  min-height: 100px;
}
.hbspt-form .hs_submit {
  margin-top: 50px;
}
.hbspt-form .hs-richtext {
  margin-top: 10px;
  margin-bottom: 10px;
}
.hbspt-form fieldset {
  margin-bottom: 5px;
}
.hbspt-form .field,
.hbspt-form .hs-form-field {
  font-family: 'Averta', "Arial", "sans-serif";
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;
}
.hbspt-form .field input.text,
.hbspt-form .hs-form-field input.text,
.hbspt-form .field input.title,
.hbspt-form .hs-form-field input.title,
.hbspt-form .field textarea,
.hbspt-form .hs-form-field textarea,
.hbspt-form .field select,
.hbspt-form .hs-form-field select {
  display: block;
  width: 100%;
  max-width: 100% !important;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-rendering: auto;
}
.hbspt-form .field input.text:focus,
.hbspt-form .hs-form-field input.text:focus,
.hbspt-form .field input.title:focus,
.hbspt-form .hs-form-field input.title:focus,
.hbspt-form .field textarea:focus,
.hbspt-form .hs-form-field textarea:focus,
.hbspt-form .field select:focus,
.hbspt-form .hs-form-field select:focus {
  border: 1px solid gainsboro;
  box-shadow: 0 0 10px gainsboro;
}
.hbspt-form .field input.text.input-validation-error,
.hbspt-form .hs-form-field input.text.input-validation-error,
.hbspt-form .field input.title.input-validation-error,
.hbspt-form .hs-form-field input.title.input-validation-error,
.hbspt-form .field textarea.input-validation-error,
.hbspt-form .hs-form-field textarea.input-validation-error,
.hbspt-form .field select.input-validation-error,
.hbspt-form .hs-form-field select.input-validation-error {
  border: 2px solid #c80046;
  background-color: transparent;
}
.hbspt-form .field input.text.input-validation-error:after,
.hbspt-form .hs-form-field input.text.input-validation-error:after,
.hbspt-form .field input.title.input-validation-error:after,
.hbspt-form .hs-form-field input.title.input-validation-error:after,
.hbspt-form .field textarea.input-validation-error:after,
.hbspt-form .hs-form-field textarea.input-validation-error:after,
.hbspt-form .field select.input-validation-error:after,
.hbspt-form .hs-form-field select.input-validation-error:after {
  content: "✖";
  color: #c80046;
  font-size: 18px;
  display: block;
  position: relative;
}
.hbspt-form .field select,
.hbspt-form .hs-form-field select {
  padding: 0px;
  border: none;
}
.hbspt-form .field select:focus,
.hbspt-form .hs-form-field select:focus {
  outline: ridge;
}
.hbspt-form .field select option:hover,
.hbspt-form .hs-form-field select option:hover {
  background-color: #c80046;
  color: #ffffff;
}
.hbspt-form input[type=checkbox] + span {
  font-weight: normal;
  text-align: justify;
  color: #FFF;
}
.hbspt-form input[type=checkbox] {
  border: none;
  margin-right: 3px;
}
.hbspt-form .hs-error-msgs {
  font-size: 14px;
  color: #620022;
  list-style: none;
  padding-left: 0px;
}
.hbspt-form .input ul {
  font-weight: normal;
  padding-left: 0px;
}
.hbspt-form .input li {
  list-style: none;
  text-decoration: none;
}
.hbspt-form input[type=submit] {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 300;
  font-family: 'Averta', "Arial", "sans-serif";
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -ms-touch-action: manipulation;
  user-select: none;
  position: relative;
  overflow: hidden;
  transition: background .2s;
  padding: 6px 50px;
  color: #ffffff;
  background-image: none;
  background: #7c0435;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: 0 4px 0 0 #620022;
  letter-spacing: normal;
  word-spacing: normal;
  text-overflow: ellipsis;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-decoration: none;
  text-align: center;
}
.hbspt-form input.text,
.hbspt-form input.title,
.hbspt-form textarea,
.hbspt-form select {
  max-width: 100% !important;
  display: block;
  width: 100%;
}
.hbspt-form input.text:focus,
.hbspt-form input.title:focus,
.hbspt-form textarea:focus,
.hbspt-form select:focus {
  outline: none;
}
.hbspt-form input.text.input-validation-error,
.hbspt-form input.title.input-validation-error,
.hbspt-form textarea.input-validation-error,
.hbspt-form select.input-validation-error {
  border: 2px solid #c80046;
  background-color: transparent;
}
.hbspt-form input.text.input-validation-error:after,
.hbspt-form input.title.input-validation-error:after,
.hbspt-form textarea.input-validation-error:after,
.hbspt-form select.input-validation-error:after {
  content: "✖";
  color: #c80046;
  font-size: 18px;
  display: block;
  position: relative;
}
.hbspt-form select option:hover {
  background-color: #c80046;
  color: #ffffff;
}
/* Act-On Form  */
.ao-form {
  margin-bottom: 15px;
}
.ao-form div[class*=-column-],
.ao-form div[class*=-columns-],
.ao-form div[class^=-column-],
.ao-form div[class^=-columns-] {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
/* Form predefined styles*/
/* Form Style 1 */
.form-style-1 div:not(.checkbox) > label {
  display: none !important;
}
.form-style-1 input:not(.btn) {
  color: #000;
}
.form-style-1 input.input-validation-error {
  background-color: #FFF !important;
}
/* Form Style 1 for red background*/
.form-style-1.redbg span.field-validation-error > span {
  color: #fff957 !important;
}
.form-style-1.redbg input.input-validation-error {
  border-color: #fff957 !important;
}
.form-style-1.redbg input[type=submit] {
  background-color: #510035;
  box-shadow: 0 4px 0 0 #480931;
}
.form-style-1.redbg input[type=submit]:hover {
  background-color: #950034;
}
.legal-consent-container {
  margin-top: 50px;
}
.filterable-text-pages-grid__filters-wrapper,
.filterable-text-pages-grid__content {
  box-sizing: border-box;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.filterable-text-pages-grid__filters-wrapper {
  justify-content: flex-start;
  margin: 0 0 25px;
}
.filterable-text-pages-grid__filters-wrapper .simple-nice-dropdown {
  max-width: 270px;
  margin-right: 25px;
}
.filterable-text-pages-grid__filters-wrapper .simple-nice-dropdown__input {
  width: 100%;
  padding: 7px 12px 8px;
  background-color: #fff;
  font-weight: bold;
}
.filterable-text-pages-grid__filters-wrapper .simple-nice-dropdown:not(.is-selected) .simple-nice-dropdown__input {
  color: #646464;
  font-weight: normal;
}
@media (max-width: 480px) {
  .filterable-text-pages-grid__filters-wrapper {
    justify-content: center;
    margin: 0 0 15px;
  }
  .filterable-text-pages-grid__filters-wrapper .simple-nice-dropdown {
    margin: 0 0 15px;
  }
}
.filterable-text-pages-grid .grid-item {
  display: none;
  padding: 15px !important;
}
.filterable-text-pages-grid .grid-item.visible {
  display: block;
}
.filterable-text-pages-grid .grid-item__inner {
  display: block;
  padding: 15px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  height: 100%;
}
.filterable-text-pages-grid .grid-item__inner--icon {
  text-align: center;
  padding: 20px 0;
  max-height: 125px;
}
.filterable-text-pages-grid .grid-item__inner--image {
  width: 100%;
  height: 125px;
  object-fit: cover;
  display: block;
}
.filterable-text-pages-grid .grid-item__inner--title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: bold;
}
.filterable-text-pages-grid .grid-item__inner--description {
  margin-top: 15px;
  font-size: 15px;
}
/* Views
********************/
