/**
 * Base: Typography
 */

h1,
h2,
h3,
h4,
h5,
.h-style {
    font-family: @header-font-family;
    font-weight: 900;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px;
    word-break: break-word;
}

h1, .h-style.h1 {
    font-size: 30px;
    @media (min-width:@screen-sm) {font-size: 42px;}
    @media (min-width:@screen-lg) {font-size: 55px;}
}

h2, .h-style.h2 {
    font-size: 21px;
    font-weight: 300;
    @media (min-width:@screen-sm) {font-size: 24px;}
    @media (min-width:@screen-lg) {font-size: 36px;}
}

h3, .h-style.h3 {
    font-size: 18px;
    font-weight: 300;
    @media (min-width:@screen-sm) {font-size: 21px;}
    @media (min-width:@screen-lg) {font-size: 24px;}
}

p.lead {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;

    @media (min-width:@screen-sm) {font-size: 21px;}
    @media (min-width:@screen-lg) {font-size: 24px;}
}

p,
ul {
  margin-bottom: 20px;
  word-break: break-word;
}

p~h2, p ~ .h-style.h2,
p~h3, p ~ .h-style.h3 {margin-top: 36px;}


// This is used to avoid the FOUT. Currently only set on headlines, but you can extend it to all your selectors.
html {
    /*h1, h2, h3, h4, h5 { opacity: 0; transition: opacity 0.3s; }*/
    &.wf-active,
    &.wf-inactive {
        h1, h2, h3, h4, h5, .h-style { opacity: 1; }
    }
}
