// Default variables
@font_size: 18px;
@font_size_small: 12px;

@input_border_radius: 3px;
@input_height: 50px;
@input_height_small: 36px;
@dropdown_padding: 18px;

@gray_dark: #444;
@gray: #999 ;
@gray_light: gainsboro;
@gray_lighter: #f6f6f6;
@primary_light: @gray;
@arrow_color: @gray ;

// Style the dropdown
.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: @input_border_radius;
  border: solid 1px @gray_light;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: @font_size;
  font-weight: normal;
  height: @input_height;
  line-height: @input_height - 2;
  outline: none;
  padding-left: @dropdown_padding;
  padding-right: @dropdown_padding + 12;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  &:hover {
    border-color: darken(@gray_light, 5%);
  }
  &:active, &:focus {
    border: 1px solid @gray_light;
  }
  // Arrow
  &:after {
    border-bottom: 2px solid @arrow_color;
    border-right: 2px solid @arrow_color;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px;
  }

  &.open {
    border-bottom: none;
    @extend :active;
    &:after {
      transform: rotate(-135deg);
    }
    .list {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1) translateY(0);
    }
  }
  &.disabled {
    border-color: lighten(@gray_light, 2%);
    color: @gray;
    pointer-events: none;
    &:after {
      border-color: lighten(@arrow_color, 20%);
    }
  }

  // Modifiers
  &.wide {
    width: 100%;
    .list {
      left: 0 !important;
      right: 0 !important;
    }
  }
  &.right {
    float: right;
    .list {
      left: auto;
      right: 0;
    }
  }
  &.small {
    font-size: @font_size_small;
    height: @input_height_small;
    line-height: @input_height_small - 2;
    &:after {
      height: 4px;
      width: 4px;
    }
    .option {
      line-height: @input_height_small - 2;
      min-height: @input_height_small - 2;
    }
  }

  // List and options
  .list {
    background-color: #fff;
    border-radius: 0;
    //box-shadow: 0 0 0 1px rgba(@gray_dark, .11);
    border: 1px solid @gray_light;
    border-top: none;
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 90%; left: 0;
    transform-origin: 50% 0;
    transform: scale(.75) translateY(- @input_height / 2);
    transition: all .2s cubic-bezier(0.5, 0, 0, 1.25), opacity .15s ease-out;
    z-index: 9;
    width: 100%;
    &:hover .option:not(:hover) {
      background-color: transparent !important;
      color: @color-text;
    }
    &:before {
      content: " ";
      width: 95%;
      height: 1px;
      background-color: @gray_light;
      z-index: 9;
      top: 0;
      position: relative;
      display: block;
      margin: 0px auto;
    }
  }
  .option {
    cursor: pointer;
    font-weight: 400;
    line-height: @input_height - 2;
    list-style: none;
    min-height: @input_height - 2;
    outline: none;
    padding-left: @dropdown_padding;
    padding-right: @dropdown_padding + 11;
    text-align: left;
    transition: all 0.2s;
    width: 100%;
    &:hover, &.focus, &.selected.focus {
      background-color: @novicell-color;
      color: @color-white;
    }
    &.selected {
      font-weight: bold;
    }
    &.disabled {
      background-color: transparent;
      color: @gray;
      cursor: default;
    }
  }
}

// Use display instead of opacity for IE <= 10
.no-csspointerevents .nice-select {
  .list {
    display: none;
  }
  &.open {
    .list {
      display: block;
    }
  }
}
