﻿#ncOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3300;
    background: rgba(0,0,0,0.5);
    display: none;
    cursor: pointer;
}

#ncOverlay-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2147483647;
    margin: auto;
    display: none;
}

.ncOverlay-inner {
    border-radius: 3px;
   margin: auto;
   position: fixed;
   top: 80px;
   left: 0;
   right: 0;
}

.ncOverlay-inner-scroll {
    overflow-y: auto;
    height: 100%;
    padding: 0 20px;
    position: relative;
}

.ncOverlay-close {
    top: -20px;
    right: -16px;
    cursor: pointer;
    position: absolute;
    color: white;

    .icon {
        width: 16px;
        height: 16px;
    }
}
