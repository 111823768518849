/**
 * Component: Accordion
 */

input.accordion-hidden {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

/* Accordion styles */
.accordions {
    border-radius: 8px;
    overflow: hidden;
}

.accordion {
    width: 100%;
    color: white;
    overflow: hidden;
}

.accordion-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #C80046;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.accordion-label:not(:last-child) {
    border-bottom: 1px solid grey;
}

.accordion-label:hover {
    background: #7C0435;
}

.accordion-label::after {
    content: "\276F";
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all .35s;
}

.accordion-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    background: white;
    transition: all .35s;
}

.accordion-content p {
    margin: 0;
}

.accordion-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #2c3e50;
    cursor: pointer;
}

.accordion-close:hover {
    background: #7C0435;
}

input:checked + .accordion-label {
    background: #7C0435;
}

input:checked + .accordion-label::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

input:checked ~ .accordion-content {
    max-height: 100vh;
    padding: 1em;
}
